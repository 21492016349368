<template>
  <div id="create-delivery-report-modal" tabindex="-1" aria-hidden="true"
    class="fixed top-0 left-0 right-0 z-50 items-center justify-center hidden w-full overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full">
    <div class="relative w-full h-full max-w-6xl p-4 md:h-auto ">
      <!-- Modal content -->
      <div class=" relative pt-4 pb-2 bg-gray-200 rounded-lg shadow dark:bg-gray-800  ">
        <!-- Modal header -->

        <div
          class="px-4 flex items-center justify-between pb-2 mb-2 border-b border-gray-200 rounded-t sm:mb-2 dark:border-gray-700">
          <h3 v-if="input?.mode == 'edit'" class="font-semibold text-gray-900 text-lg dark:text-white  ">
            {{ labels['TITLE_MAIN_EDIT_DELIVERY'][lg] }} {{batch_number}} {{delivery_number}}
          </h3>
          <h3 v-if="input?.mode == 'create'" class="font-semibold text-gray-900 text-lg dark:text-white  ">
            {{ labels['TITLE_MAIN_CREATE_DELIVERY'][lg] }}
          </h3>

          <button type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 inline-flex dark:hover:bg-gray-600 dark:hover:text-white"
            @click=" onClose()">
            <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd" />
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <div class="relative bg-gray-50 rid gap-4 mb-4 sm:grid-cols-1 sm:gap-6 sm:mb-5 p-4">
          <div class="w-full  flex flex-col ">




            <div class="mt-2 w-full    flex">


              <div class=" w-6/12  text-left justify-start mr-2 flex flex-row items-center py-2">
                <label for="hunting-item-number"
                  class="w-36 block   text-sm font-semibold text-gray-900 dark:text-white mr-2">
                  {{ labels['TITLE_DONE_AT'][lg] }}{{input.mode}}</label>
                <ContentDate :id="`content-date-input-${id}-delivery-report`" class="max-w-36     "  :mode="input.mode"
                  ref="content-date-input-delivery-report" :config="config_date" index="200" placeholder=""
                  :value="done_at" @update-field="onDateValueChanged" @value-changed="onDateValueChanged" />
              </div>

              <div class=" w-6/12  text-left justify-start mr-2 flex flex-row items-center py-2">
                <label for="game_specie_id" class="w-36  block   text-sm font-semibold text-gray-900 dark:text-white">
                  {{ labels['TITLE_DONE_BY'][lg] }}</label>
                <select id="hunting-item-nemrod_comment_id" v-model="member_id"
                  class="w-72  bg-gray-50 border border-gray-300 text-gray-900   text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block   p-2  ">
                  <option v-for="member in members" :key="member._id" :value="member._id"> {{member.name}} </option>
                </select>
              </div>

            </div>
<hr />


            <div class="mt-2 w-full    flex">

              <div class=" w-6/12  text-left justify-start mr-2 flex flex-row items-center py-2">
                <label for="game_specie_id" class="w-36  block   text-sm font-semibold text-gray-900 dark:text-white">
                  {{ labels['TITLE_HUNTER'][lg] }} </label>
                <select id="hunting-item-nemrod_comment_id" v-model="hunter_id"
                  class="w-72  bg-gray-50 border border-gray-300 text-gray-900   text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block   p-2   "
                  @change="onHunterChanged">
                  <option v-for="hunter in hunters" :key="hunter._id" :value="hunter._id">{{hunter.name}} -
                    {{hunter.reference}}</option>

                </select>

              </div>

              <div
                class=" w-6/12  text-left justify-start mb-2  flex flex-row items-center justify-start text-left py-2">
                <label for="price_id" class="w-36  block   text-sm font-semibold text-gray-900 dark:text-white">
                  {{ labels['TITLE_PRICE_GRID'][lg] }} {{pricing_catalog_id}}</label>
                <select id="price_id" v-model="pricing_catalog_id"
                  class="w-72  bg-gray-50 border border-gray-300 text-gray-900   text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block   p-2   "
                  @change="onSelectType">
                  <option v-for="price in prices" :key="price._id" :value="price._id"> {{price.name}} </option>
                </select>

              </div>

            </div>
            <div class="mt-2 w-full    flex">

              <div class=" w-6/12  text-left justify-start mr-2 flex flex-row items-center py-2">
                <label for="game_specie_id" class="w-36  block   text-sm font-semibold text-gray-900 dark:text-white">
                  {{ labels['TITLE_TYPE'][lg] }} </label>
                <select id="hunting-item-nemrod_comment_id" v-model="type"
                  class="w-72  bg-gray-50 border border-gray-300 text-gray-900   text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block   p-2  "
                  @change="onSelectType">
                  <option value="depot"> RECEPTIONNE </option>
                  <option value="collecte"> COLLECTE </option>
                </select>

              </div>



            </div>


            <div class="mt-2 w-full    flex">

              <div class=" w-6/12  text-left justify-start mr-2 flex flex-row items-center py-2">
                <label for="hunting-item-number"
                  class="w-36 block   text-sm font-semibold text-gray-900 dark:text-white mr-2">
                  {{ labels['TITLE_TEMPERATURE'][lg] }}</label>
                <input id="hunting-item-number" v-model="temperature" v-focus type="text" name="hunting-item-number"
                  class="max-w-10 font-bold bg-gray-50 border border-gray-300 text-gray-600 text-sm  text-center center rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="">
                <span class="ml-1">{{temperature_unit}}</span>
              </div>
            </div>


            <div class=" w-full flex flex-row   mt-6 py-2">


            </div>

          </div>
          <div class="absolute top-0 left-0 w-full h-full  bg-gray-50 flex items-center justify-center"
            :class="{ 'block' : !show_form, 'hidden':show_form}">
            <div class="flex items-center">


              <div role="status">
                <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor" />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill" />
                </svg>
                <span class="sr-only">Loading...</span>
              </div>
              <div class="font-bold text-lg ml-2">
                Chargement en cours
              </div>

            </div>
          </div>
        </div>

        <div class="flex items-center justify-end px-4  ">
          <button type="button"
            class="mr-3 py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
            @click=" onClose()">
            Annuler
          </button>
          <button type="button" :disabled="disabledValidate"
            class="inline-flex items-center text-white   font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-500 dark:hover:bg-gray-600 dark:focus:ring-gray-900"
            :class="{'bg-gray-400':disabledValidate, 'bg-gray-600 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300':!disabledValidate}"
            @click="onValidate">
            Modifier
          </button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import labels from "@/assets/lg/hunting-batch.json"
  import service from "@/services/FullService.vue"
  import { mapGetters } from "vuex";

  import ContentDate from '@/components/content/ContentDate.vue'
  export default {
    name: 'CreateHuntingModalModal',
    components: {
      ContentDate
    },
    props: {
      input: Object
    },
    data() {
      return ({
        show_form: false,
        current_tab: 1,

        view: "ba",
        hunters: [],

        members: [],
        prices: [],
        config_date: {},

     
        lg: "FR",
        labels: labels,

        item_id: "",

        done_at : "",

        hunter_id: "",
        member_id: "",

        pricing_catalog_id: "",
        type: "",

        id: "",
        name: "",
        reference: "",

        temperature: "",
        temperature_unit: "",

        state: "",
        transparent: false,
        item: {
          id: "1",
          label: {
            'EN': "",
            'FR': ''
          }
        },
      })
    },
    watch: {



      'input': async function () {

        if (this.hunters.length == 0) {
          await this.loadHunters()

        }

        if (this.members.length == 0) {
          await this.loadActors()
        }

        if (this.prices.length == 0) {
          await this.loadPrices()
        }


        // this.done_at
        this.price_catalog_id = this.input.price_catalog_id
        this.type = this.input.source?.type
        this.member_id = this.input.source?.member_id

        this.hunter_id = this.input?.source?.hunter_id
        this.pricing_catalog_id = this.input?.source?.pricing_catalog_id
        
        this.temperature = this.input?.source?.temperature
        this.temperature_unit = this.input?.source?.temperature_unit

        this.member_id = this.input?.source?.member_id
        this.show_form = false
        this.view = this.input?.view
        this.show_form = true
        // this.name = this.input
      }
    },
    async mounted() {
      this.view = this.input?.view
    },
    computed:
    {
      ...mapGetters({ user: "StateUser" }),


      disabledValidate: function () {
        if (this.weight_gross == "") return true;
        return false
      },
    },
    methods: {

      onClickTab(n) {
        this.current_tab = n;
      },

      onDateValueChanged(item) {
        this.done_at = item;
        alert(JSON.stringify(item))
      },


      onHunterChanged() {

        let id = this.hunter_id
        let h = this.hunters.find(function (a) { return a?._id?.toString() == id })
        this.pricing_catalog_id = h.pricing_id
      },

      async loadHunters() {
        {
          //inbound-pricing-lists
          let datasource = "675473457b9529cfdc2feb61"

          let filters = {}
          let collection = "data-sources/" + datasource + "/records?limit=500"
          let hunters = await service.getData(collection, this.user?.token, filters)

          this.hunters = hunters.records;
        }

      },


      async loadPrices() {
        {
          //inbound-pricing-lists
          let datasource = "6752cb0d7b9529cfdc2feb51"

          let filters = {}
          let collection = "data-sources/" + datasource + "/records?limit=500"
          let prices = await service.getData(collection, this.user?.token, filters)

          this.prices = prices.records;
        }

      },



      async loadActors() {
        {
          //inbound-pricing-lists
          let datasource = "6752c9b77b9529cfdc2feb50"

          let filters = {}
          let collection = "data-sources/" + datasource + "/records?limit=500"
          let members = await service.getData(collection, this.user?.token, filters)
          //alert(JSON.stringify(members))
          this.members = members.records;
        }

      },


 

      onValidate() {

        let g = this.$refs['content-date-input-delivery-report']
        let done_at = g.getData()
      
        let item =
        {
          id: this.id,

          action: this.input.action,
          source: {
            record_id : this.input?.source?.item_id
          },
          target:
          {
            done_at : done_at,
            member_id : this.member_id,

            hunter_id : this.hunter_id,
            pricing_catalog_id : this.pricing_catalog_id,
            type : this.type,
         
            temperature : this. temperature,
            temperature_unit : this. temperature_unit
          }
        }

        this.$emit("confirm", item)
        this.show_form = false
        // this.name = ""
      },



      onClose() {
        this.$emit("close")
        this.show_form = false
        //  this.name = ""
      }
    },
  }
</script>