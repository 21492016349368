<template>

  <div class="w-full ">

    <component :is="mapComponent(charttype)" :parent="id" :period="period" :records="localReports" :config="config"
      :refresh="refresh" @refresh-chart="onRefreshChart" />


  </div>
</template>

<script>
  //  import common_labels from "@/assets/lg/common.json"
  import service from "@/services/FullService.vue"
  import CounterChart from "@/components/charts/CounterChart.vue"
  import ColumnBarChart from "@/components/charts/ColumnBarChart.vue"
  import LineChart from "@/components/charts/LineChart.vue"
  import SemiDonutChart from "@/components/charts/SemiDonutChart.vue"
  import TreeMapChart from "@/components/charts/TreeMapChart.vue"
  import RowBarChart from "@/components/charts/RowBarChart.vue"


  /*
      <HeaderGraph v-if="type=='header'" :id="`core-${id}`" class="hidden" :records="localReports" :title="config.title"
        :config="config" :refresh="refresh" />
      <PieChart v-if="type=='item.graph.pie'" :id="`core-${id}`" class="hidden" :title="config.title"
        :refresh="refresh" />
      <TimeLineChart v-if="type=='timeline'" :id="`core-${id}`" class="hidden" :title="config.title" :refresh="refresh" />
      <AreaChart v-if="type=='area'" :id="`core-${id}`" class="hidden" :title="config.title" :refresh="refresh" />
      <RangeAreaChart v-if="type=='range-area'" class="hidden" :refresh="refresh" />
      <ComboLineBarChart v-if="type=='combo-line-bar'" class="hidden" :refresh="refresh" />
      <CandleStickChart v-if="type=='candle-stick'" :id="`core-${id}`" class="hidden" :title="config.title"
        :refresh="refresh" />
      <Fake v-if="type=='fake'" :id="`core-${id}`" :title="config.title" :source="source" :config="item"
        :records="reports" :refresh="refresh" />
      <RowBoxPlotChart v-if="type=='row-box-plot'" class="hidden" :refresh="refresh" />
      <ColumnBoxPlotChart v-if="type=='column-box-plot'" class="hidden" :refresh="refresh" />
      <ScatterChart v-if="type=='scatter'" class="hidden" :refresh="refresh" />
      <TreeMapChart v-if="type=='tree-map'" :records="localReports" :config="item" :refresh="refresh"
        @refresh-chart="onRefreshChart" />
      <BubbleChart v-if="type=='bubble'" :records="localReports" :config="item" :refresh="refresh"
        @refresh-chart="onRefreshChart" />
      <RadarChart v-if="type=='radar'" :records="localReports" :parent="id" :config="item" :refresh="refresh"
        @refresh-chart="onRefreshChart" />
      <RadialBarChart v-if="type=='radial-bar'" :records="localReports" :parent="id" :config="item" :refresh="refresh"
        @refresh-chart="onRefreshChart" />
      <PolarChart v-if="ype=='polar'" :records="localReports" :parent="id" :config="item" :refresh="refresh"
        @refresh-chart="onRefreshChart" />
      <RowBarChart v-if="type=='row-bar'" :parent="id" :records="localReports" :config="item" :refresh="refresh"
        @refresh-chart="onRefreshChart" />
      <DonutChart v-if="type=='donut'" :parent="id" :records="localReports" :config="item" :refresh="refresh"
        @refresh-chart="onRefreshChart" />
       
      <PieChart v-if="type=='pie'" :parent="id" :records="localReports" :config="item" :refresh="refresh"
        @refresh-chart="onRefreshChart" />
      <SemiDonutChart v-if="type=='semi-donut'" :parent="id" :records="localReports" :config="item" :refresh="refresh"
        @refresh-chart="onRefreshChart" />
      <FunnelChart v-if="type=='funnel'" :parent="id" :records="localReports" :config="item" :refresh="refresh"
        @refresh-chart="onRefreshChart" />
      <FigureSparkLineChart v-if="graph.type=='sparkline'" :parent="config.id" :records="localReports" :config="item"
        :value="value" @refresh-chart="onRefreshChart" />
      <FigureSparkLineChart v-if="graph.type=='lines'" :parent="config.id" :records="localReports" :config="config"
        :value="value" @refresh-chart="onRefreshChart" />
    
     
  
  
  
        <CounterChart v-if="charttype=='counter'" :parent="id" :records="localReports" :config="config" :refresh="refresh"
        @refresh-chart="onRefreshChart" />
      <ColumnBarChart v-if="charttype=='column-bar'" :parent="id" :records="localReports" :config="config"
        :refresh="refresh" @refresh-chart="onRefreshChart" />
      <LineChart v-if="charttype=='line'" :parent="id" :records="localReports" :config="config" :refresh="refresh"
        @refresh-chart="onRefreshChart" />
  
  */
  /*
 
  import HeaderGraph from "@/components/charts/HeaderGraph.vue"
  import RadarChart from "@/components/charts/RadarChart.vue"
  import AreaChart from "@/components/charts/AreaChart.vue"
  import ComboLineBarChart from "@/components/charts/ComboLineBarChart.vue"
  import RowBoxPlotChart from "@/components/charts/RowBoxPlotChart.vue"
  import ColumnBoxPlotChart from "@/components/charts/ColumnBoxPlotChart.vue"
  import FigureSparkLineChart from "@/components/charts/FigureSparkLineChart.vue"
  import RadialBarChart from "@/components/charts/RadialBarChart.vue"
  import ScatterChart from "@/components/charts/ScatterChart.vue"
  import PolarChart from "@/components/charts/PolarChart.vue"
 
  import Fake from "@/components/charts/Fake.vue"
  import TimeLineChart from "@/components/charts/TimeLineChart.vue"
  import DonutChart from "@/components/charts/DonutChart.vue"
  import FunnelChart from "@/components/charts/FunnelChart.vue"
  

 
  import RangeAreaChart from "@/components/charts/RangeAreaChart.vue"
  import CandleStickChart from "@/components/charts/CandleStickChart.vue"
  import PieChart from "@/components/charts/PieChart.vue"
  import BubbleChart from "@/components/charts/BubbleChart.vue"
  
*/

  //import { Modal } from 'flowbite';
  import { mapGetters } from "vuex";
  export default {
    name: 'ChartComponent',
    components: {
      CounterChart,
      ColumnBarChart,
      LineChart,
      SemiDonutChart,
      RowBarChart,
      TreeMapChart
      /*     
      
           Fake,
          
         
           RangeAreaChart,
           TreeMapChart,
           DonutChart,
           FunnelChart,
           PieChart,
           RadarChart,
           PolarChart,
         
           AreaChart,
           TimeLineChart,
           ComboLineBarChart,
           CandleStickChart,
           RowBoxPlotChart,
           ColumnBoxPlotChart,
         
           RadialBarChart,
           BubbleChart,
           ScatterChart,
          
           FigureSparkLineChart,
           HeaderGraph*/
    },

    props: {
      id: String,
      charttype: String,
      config: Object,
      period: Object,
      refresh: String
    },
    data() {
      return {
        localReports: {},
        source: null // A Garder ???
      }
    },
    computed: {
      ...mapGetters({ user: "StateUser" }),
      isLoggedIn: function () {
        return this.$store.getters.isAuthenticated;
      },
    },
    mounted() { },
    methods: {

      mapComponent: function (type) {
        if (type == 'counter') return CounterChart;
        if (type == 'column-bar') return ColumnBarChart;
        if (type == 'semi-donut') return SemiDonutChart;
        if (type == 'tree-map') return TreeMapChart;
        if (type == 'row-bar') return RowBarChart;
        if (type == 'line') return LineChart;
        return LineChart;
      },

      getSectionHeight: function (item) {
        if (item == undefined) return "h-96"
        return 'h-' + item.height
      },

      getContainerClass: function (item) {
        if (item == undefined) return ''
        //   return 'grid grid-cols-' + item.cols.length + ' gap-4 mb-6'
        return ' ' + item.class_container
      },

      getSectionClass: function (item) {
        if (item == undefined) return ''
        //   return 'grid grid-cols-' + item.cols.length + ' gap-4 mb-6'
        return ' ' + item.class
      },

      getBodyClass: function (col) {
        // if (item == undefined) return
        if (col == undefined) return ''
        return col.class
        //return 'h-' + col.height + ' grid   gap-4  grid grid-cols-' + col.items.length + ' gap-4 mb-6'
      },

      async getRemoteData(id) {
        let filter = {}

        if (id == undefined) {
          return {
            total: 0,
            records: []
          }
        }

        let records = await service.generateReport("data-sources/" + id + '/charts', this.user?.token, Object.assign({}, { limit: 10, offset: 0 }, filter))

        // console.log("Chart mounted")
        // console.log(JSON.stringify(records))
        return records
      },
      onRefreshChart: async function (item) {
        //  console.log("onRefeshChart onRefeshChart onRefeshChart onRefeshChart")
        //  console.log("id", item.id)
        //  console.log("source", item?.config?.source?.source?.id)
        //  console.log(item)

        let source_id = item?.config?.source?.id
        // let source_id = "663522bde3498f69e81035bd"

        let response = await this.getRemoteData(source_id)
        //  console.log("response <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<", item.id)
        //  console.log(response)
        //this.reports[item.id] = response
        this.localReports = response
      },



    },
  }

</script>