<template>
  <div class="relative   w-full h-64   flex justify-center items-center p-2 pt-20">
    <apexchart class="h-auto  w-full flex items-center  justify-center " type="bar" :options="chartOptions"
      :series="series" />
  </div>
</template>

<script>
  import VueApexCharts from "vue3-apexcharts";
  export default {
    name: 'RowBarChart',
    components: {
      apexchart: VueApexCharts,
    },
    props: {
      parent: String,
      title: String,
      records: Object,
      config: Object,
      refresh: String
    },
    data: function () {
      return {
        chartOptions: {
          chart: {
            type: 'bar',
            //stacked: true,
            toolbar: {
              show: true,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: false,
                selection: false,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: false,
                reset: false,
                customIcons: []
              },
              export: {

              },
              autoSelected: 'zoom'
            },


          },
          title: {
            text: this.title
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
            }
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            categories: [],
          }
 
        },
        series: [
          {
            name: "Produits",
            data: []
          },
        ],
      };
    },
    watch: {
      'refresh': function () {
        console.log("RowBarChart WATCH records")
        if (this.config != undefined) this.$emit("refreshChart", { config: this.config, id: this.config.id })
      },

      'records': async function (val) {
 
     
        console.log("RowBarChart WATCH records")
        // console.log("ColumnBarChart WATCH records")
        // console.log("ColumnBarChart WATCH records")
        // console.log(val)
        // console.log("ColumnBarChart WATCH records")
        // console.log("ColumnBarChart WATCH records")
        // console.log(val)

       
        let r = this.transformData(val);
        if (r == undefined) r = { data: [], name: "" }
        this.localrecords = r
 
        console.log("////////////////////////////////////////////////////")
        console.log("////////////////////////////////////////////////////")
        console.log("////////////////////////////////////////////////////")
        console.log(r.series)
 
        this.series[0].data = r.series[0].data;
        this.chartOptions =
        {
          xaxis: {
            categories: r.categories
          }
          //    labels : r.categories
        }
          
        //     this.series[0].name = r.series[0].name;
      }


    },
    async mounted() {

      if (this.config != undefined) this.$emit("refreshChart", { config: this.config, id: this.id })


    },
    methods: {
      transformData(data) {

        // console.log("RowBar - RowBar RowBar RowBar RowBar RowBar", data.records)
        //  console.log(JSON.stringify(data))
        let series = []
        let labels = {}


        {
          for (let i = 0; i < data.records.length; i++) {
            if (data.records[i]) {
              series.push(

                  data.records[i]['x'] 

              )
              labels[data.records[i]['y']] = data.records[i]['y']
            }

          }
        }


        //  console.log("series", data.records.length)
        //  console.log(series)
        //  console.log(labels)
        //  console.log(Object.keys(labels))
        return { categories: Object.keys(labels), series: [{ name: "test", data: series }] }
      }
    }

  }
</script>