<template>
  <section class="w-full bg-gray-50 dark:bg-gray-900  ">
    <div v-if="isLoading" class="w-full   mx-auto max-w-screen-2xl  ">
      <h1 class="text-2xl mt-6">
        {{ msg_waiting[lg] }}
      </h1>
    </div>
    <div class="hidden">
      current_page: {{ current_page }} /max_page: {{ max_page }} / start_page: {{ start_page }}<br>
      {{ Math.ceil(current_page /2) }}
    </div>
    <div v-if="!isLoading" class="w-full   mx-auto   ">
      <div v-if=" (config?.options?.filterBar == true)"
        class="h-12 mb-2 flex flex-col flex-shrink-0 space-y-3 md:flex-row md:items-center lg:justify-start md:space-y-0 md:space-x-3">
        <div
          class="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
          <ul class="flex flex-wrap -mb-px">
            <li v-for="tab in config?.tabs" :key="tab.id" class="mr-2">
              <div
                :class="{ 'text-blue-600 border-blue-600 active  dark:text-blue-500 dark:border-blue-500 ': category==tab.id, 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300': category!=tab.id}"
                class="cursor-pointer inline-block p-4  border-b-2 rounded-t-lg  " aria-current="page"
                @click="selectItemType(tab.id)">
                {{ tab.name[lg] }}
              </div>
            </li>
          </ul>
        </div>
      </div>


      &ThinSpace;




      <div class="relative overflow-hidden h-full  bottom-4 bg-white shadow-md dark:bg-gray-800  "
        style="min-height:750px">
        <div v-if="config != undefined && (config.options == undefined || config.options.topBar != false)"
          class="flex flex-col px-4 py-3 space-y-3 lg:flex-row lg:items-center lg:justify-between lg:space-y-0 lg:space-x-4">
          <div class="flex items-center w-full space-x-3">
            <div class="pr-3 " :class="{'invisible': !config?.options?.displayBack}">
              <a class="inline-flex justify-center p-1 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                @click="onBack">
                <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                    clip-rule="evenodd" />
                </svg>
              </a>
            </div>



            <div
              class="flex ring-0 items-center w-2/5 rounded-lg bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block  py-1">
              <div class="flex flex-col  w-full bg-gray-400">
                <div id="mega-menu-dropdown-button" class="relative   flex items-start w-full"
                  data-dropdown-offset-distance2="2" data-dropdown-offset-skidding2="0" @change2="closeSearchBar"
                  @keyup="closeSearchBarKeyup($event)">
                  <div class="absolute  inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                    </svg>
                  </div>
                  <input id="input-search" v-model="globalQuery" type="text" style=" outline: none;"
                    class="bg-gray-50 border-none  w-full text-gray-900 text-sm  focus:border-0 focus:ring-0 ring-2 ring-transparent ring-inset  border-transparent block w-full pl-10 pr-2  "
                    placeholder="Nom, Référence ..." @input="onSearchInput" @focus="onSeachFocus($event)">

                  <button type="button" class="hidden  absolute inset-y-0 right-6 flex items-center pr-3"
                    @click="onEraseSearch($event)">
                    <svg class="w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                      <path
                        d="M18.85 1.1A1.99 1.99 0 0 0 17.063 0H2.937a2 2 0 0 0-1.566 3.242L6.99 9.868 7 14a1 1 0 0 0 .4.8l4 3A1 1 0 0 0 13 17l.01-7.134 5.66-6.676a1.99 1.99 0 0 0 .18-2.09Z" />
                    </svg>
                  </button>
                  <button :class="{'hidden2': !isExtended}" type="button"
                    class="  absolute inset-y-0 right-0 flex items-center pr-3" @click="onEraseSearch($event)">
                    <svg class="w-3 h-3 text-gray-800 dark:text-white" aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                    </svg>
                  </button>

                  <div id="mega-menu-dropdown"
                    class="  z-10    hidden w-full    bg-gray-100 border border-gray-300 rounded-b-lg   dark:border-gray-700 dark:bg-gray-700">
                    <div v-if="globalQuery?.length > 0"
                      class=" px-4 pt-4 pb-2  text-gray-900 md:pb-2 dark:text-white overflow-y-auto">
                      <ul class="space-y-1  overflow-y-auto min-h-48 max-h-48">
                        <li v-for="search in results?.matching" :key="search.id">
                          <button type="button"
                            class="relative inline-flex items-center w-full px-4 pt-2 pb-1 text-sm font-medium  hover:bg-gray-200 hover:text-gray-700  hover:font-bold  dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white"
                            @click="onSearchItemSelected($event, {reference : search.reference, name : search.name})">
                            <div class="w-full flex items-center  ">
                              <svg class="w-4 h-4 mr-2.5 text-gray-500 dark:text-white"
                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path
                                  d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
                              </svg>
                              <div class="w-full justify-start text-left">
                                {{ search.name }}
                              </div>
                              <div class="justify-end text-right">
                                {{ search.reference }}
                              </div>
                            </div>
                          </button>
                        </li>
                      </ul>
                      <div class="border-t border-gray-700 flex justify-center items-end  ">
                        <button type="button"
                          class="flex items-center justify-center flex-shrink-0 px-3 py-2 text-lg font-bold text-gray-700       focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10       "
                          @click="onClickDisplayAll($event)">
                          {{ common_labels['BUTTON_SEE_ALL'][lg] }}
                        </button>
                      </div>
                    </div>

                    <div v-if="globalQuery == ''"
                      class="bg-gray-100  px-3 pt-2 pb-0 text-gray-900 md:pb-2 dark:text-white overflow-y-auto">
                      <div
                        class="  mt-1 w-full border-b-2   border-b-gray-300   flex flex-row items-center py-1  bg-red-100">
                        <button v-for="button in results?.buttons" :key="button" type="button"
                          class="py-0.5 px-3  mr-1 mb-1 text-xs font-medium text-gray-900 focus:outline-none bg-white rounded-sm border border-gray-400 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                          @click="onSelectQuickSearch($event, button)">
                          {{ button?.name && button?.name[lg] }}
                        </button>
                      </div>
                      <label
                        class="flex mb-1 w-full font-bold text-sm text-gray-800 text-left  border-t border-gray-500 border-t justify-start pt-2">
                        Récents
                      </label>
                      <ul class="space-y-1 h-48 overflow-y-auto">
                        <li v-for="search in results?.latest" :key="search.id">
                          <button type="button"
                            class="relative inline-flex items-center w-full px-4 pt-2 pb-1 text-sm font-medium  hover:bg-gray-200 hover:text-gray-700  hover:font-bold focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white"
                            @click="onSearchLastestItemSelected($event,search)">
                            <svg class="w-3 h-3 mr-2.5 text-gray-800 dark:text-white" aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                              <path stroke="currentColor" stroke-linejoin="round" stroke-width="2"
                                d="M10 6v4l3.276 3.276M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>
                            {{ search.name }}
                          </button>
                        </li>
                      </ul>
                      <label
                        class="hidden flex mb-1 w-full font-bold text-sm text-gray-800 text-left  border-t border-gray-500 border-t justify-start pt-2">Suggestions</label>

                      <ul class="hidden h-48 overflow-y-auto">
                        <li v-for="search in results?.suggestions" :key="search.id">
                          <button type="button"
                            class="relative inline-flex items-center w-full px-2 py-2 text-sm font-medium rounded-b-lg hover:bg-gray-200 hover:font-bold hover:text-gray-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white">
                            <svg class="w-3 h-3 mr-2.5 text-gray-500 dark:text-white" xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512">
                              <path
                                d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
                            </svg>
                            {{ search.name }}
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  class="hidden w-96 text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  :class="{'hidden': !isExtended}">
                  <button type="button"
                    class="relative inline-flex items-center w-full px-4 py-2 text-sm font-medium rounded-b-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white">
                    <svg class="w-3 h-3 mr-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor" viewBox="0 0 20 20">
                      <path
                        d="M14.707 7.793a1 1 0 0 0-1.414 0L11 10.086V1.5a1 1 0 0 0-2 0v8.586L6.707 7.793a1 1 0 1 0-1.414 1.414l4 4a1 1 0 0 0 1.416 0l4-4a1 1 0 0 0-.002-1.414Z" />
                      <path
                        d="M18 12h-2.55l-2.975 2.975a3.5 3.5 0 0 1-4.95 0L4.55 12H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2Zm-3 5a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
                    </svg>
                    Download
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            class="flex flex-col flex-shrink-0 space-y-3 md:flex-row md:items-center lg:justify-end md:space-y-0 md:space-x-3">



            <button v-for="bt in config.buttons" :key="bt.id" type="button"
            class="flex items-center justify-center px-4 py-2 text-sm font-medium  rounded-lg  focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
           
            :class="bt.class"
           
            @click="onClickMenuButton(bt)">
            <i class=" mr-2" :class="bt.icon"/>
            {{ bt.label[lg] }}
          </button>




            <button type="button"
              class="hidden flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-gray-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
              @click="onClickUp">
              <i class="fa-solid fa-arrow-up  mr-2" />
              {{ common_labels['BUTTON_ADD'][lg] }}
            </button>

            <button v-if="selected?.length > 0" type="button"
              class="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-red-700 hover:bg-danger-800 focus:ring-4 focus:ring-danger-300 dark:bg-primary-600 dark:hover:bg-danger-700 focus:outline-none dark:focus:ring-danger-800"
              @click="onClickDelete">
              <i class="fa-solid fa-trash  mr-2" />
              {{ common_labels['BUTTON_DELETE'][lg] }}
            </button>

            <button v-if="config && config.options && config.options.showAdd != false" type="button"
              class="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-gray-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
              @click="onClickAdd">
              <i class="fa-solid fa-plus  mr-2" />
              {{ common_labels['BUTTON_ADD'][lg] }}
            </button>

            <button v-if="selected?.length == 1" type="button"
              class="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-green-700 hover:bg-danger-800 focus:ring-4 focus:ring-danger-300 dark:bg-primary-600 dark:hover:bg-danger-700 focus:outline-none dark:focus:ring-green-800"
              @click="onClickDuplicate">
              <i class="fa-solid fa-clone  mr-2" />
              {{ common_labels['BUTTON_DUPLICATE'][lg] }}
            </button>

            <button type="button"
              class="flex items-center justify-center flex-shrink-0 px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              @click="onClickRefresh">
              <i class=" mr-2 fa-solid fa-arrows-rotate" />

              {{ common_labels['BUTTON_REFRESH'][lg] }}
            </button>

            <button type="button"
              class="flex items-center justify-center flex-shrink-0 px-3 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              @click="onClickExport">
              <i class="fa-solid fa-download mr-2 " />
              {{ common_labels['BUTTON_EXPORT'][lg] }}
            </button>
          </div>
        </div>

        <nav class="hidden flex p-4" aria-label="Breadcrumb">
          <ol class="inline-flex items-center space-x-1 md:space-x-3">
            <li class="cursor-pointer inline-flex items-center">
              <div
                class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
                @click="onClickBreadCrumb('/')">
                <svg aria-hidden="true" class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                </svg>
                Home
              </div>
            </li>
            <li>
              <div class="flex items-center">
                <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd" />
                </svg>
                <a href="#"
                  class="ml-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Projects</a>
              </div>
            </li>
            <li aria-current="page">
              <div class="flex items-center">
                <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd" />
                </svg>
                <span
                  class="cursor-pointer ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">Flowbite</span>
              </div>
            </li>
          </ol>
        </nav>

        <div v-if="config != undefined && (config.options == undefined || config?.options?.secondBar != false)"
          class=" flex flex-col-reverse items-start justify-between p-4 border-b md:flex-row md:items-center md:space-x-4 dark:border-gray-700">
          <div class="mt-3 md:mt-0 hidden">
            <button id="actionsDropdownButton" data-dropdown-toggle="actionsDropdown"
              class="flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg md:w-auto focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              type="button">
              <svg class="-ml-1 mr-1.5 w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path clip-rule="evenodd" fill-rule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
              </svg>
              Actions
            </button>
            <div id="actionsDropdown"
              class="z-10 hidden bg-white divide-y divide-gray-100 rounded shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
              <ul class="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="actionsDropdownButton">
                <li>
                  <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                    Mass Edit
                  </a>
                </li>
              </ul>
              <div class="py-1">
                <a href="#"
                  class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">
                  Delete all
                </a>
              </div>
            </div>
          </div>

          <div class="grid w-full grid-cols-1 md:grid-cols-4 lg:w-2/3 md:gap-4">
            <div v-for="filterBox in config.secondBar" :key="filterBox.id" class="w-full flex items-center">
              <label :for="`item_category_${filterBox.id}`"
                class="sr-only2 mr-2 text-sm font-bold text-right truncate w-full">{{ filterBox.name[lg] }}
                :</label>

              <select :id="`item_category_${filterBox.id}`" :value="filterBox.selected"
                class="block py-1.5 px-0 w-full text-sm text-gray-500 bg-transparent    font-bold  border border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                @change="changeSelect($event, filterBox.data)">
                <option v-for="filterOption in filterBox.values" :key="filterOption.value" :value="filterOption.value">
                  &nbsp;{{ filterOption.name[lg] ? filterOption.name[lg] :
                  filterOption.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div v-if="isLoading2" class="relative overflow-hidden bg-white shadow-md dark:bg-gray-800  ">
          <h1 class="text-2xl mt-6 text-black">
            {{ msg_waiting[lg] }}
          </h1>
        </div>
        <div class="overflow-x-auto relative pb-20  " v2-if="!isLoading2">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th v-for=" col in config.cols" :key="col.id" scope="col" class=" py-1  bg-gray-300   "
                  :class="{'cursor-pointer hover:bg-gray-200' : (col.sort != undefined), 'pointer-events-none': (col.sort == undefined)}"
                  @click="sortCol(col)">
                  <div class="flex flex-row items-center" :class="col.styleh">
                    <div v-if="col.type=='check'" class="flex items-center">
                      <input id="checkbox-all" type="checkbox"
                        class="w-4 h-4   border-gray-300 rounded text-primary-600 focus:ring-primary-500    focus:ring-2  ">
                      <label for="checkbox-all" class="sr-only" />
                    </div>
                    <div v-if="col.type!='check' && col.sort != undefined" class="w-full px-2 py-2">
                      {{ col.name ? col.name[lg] : "-" }}
                    </div>
                    <i v-if="sortOrder == 'down' && sortColId == col.id" class="ml-2 fa-solid fa-sort-down" />
                    <i v-if="sortOrder == 'up' && sortColId == col.id" class="ml-2  fa-solid fa-sort-up" />
                    <i v-if="sortColId != col.id" class="ml-2  fa-solid fa-sort opacity-0 hover:opacity-100" />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody v-if="records != undefined " v2-if="&&  !isLoading3" style="min-height:200px"
              :class="{'opacity-40' : (isLoading2)}">
              <tr v-for="record in records?.records" :key="record._id"
                class=" border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700"
                :class="{ 'cursor-pointer' : (this.config.clickrow != false)}" @click="onRowClick(record)">
                <td v-for="col in config.cols" :key="col.id" :class="col.style" class="px-0 py-0  h-16  ">
                  <div v-if="col.type=='check'" class="flex items-center w-4 h-4 w-4 h-4">
                    <input id="checkbox-table-search-1" type="checkbox"
                      class="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      @click="onClickCheck($event,record)">
                  </div>
                  <div v-if="col.type=='image-title-subtitle'" class="pr-2   h-full   flex items-center">
                    <div v-if="col.image_url == undefined" :class="col.image_class"
                      class=" h-full   bg-gray-500 text-white font-bold flex justify-center items-center">
                      {{ buildAvatar(col.data, colsubdata, record) }}
                    </div>
                    <div v-for="item in getImageTitleData(record, col)" :key="item.id"
                      class="flex flex-row items-center " :class="col.class">
                      <img v-if="col.image_url != undefined" :class="col.image_class" class="   "
                        :src="imagePath(item)">
                      <div class="pl-3">
                        <div class="text-base font-semibold text-sm  " v-html="item.title" />
                        <div class="font-normal text-gray-500" v-html="item.subtitle" />
                      </div>
                    </div>
                  </div>





                  <div v-if="col.type=='image-title-subtitle-array'" class="px-2   flex items-center">
                    <div v-for="item in getImageTitleDataArray(record, col)" :key="item.id"
                      class="flex flex-row items-center" :class="col.class">
                      <img v-if="col.image_url != undefined" :class="col.image_class" :src="imagePath(item)">

                      <div class="pl-3">
                        <div class="text-base font-semibold text-sm" v-html="item.title" />

                        <div class="font-normal text-gray-500" v-html="item.subtitle" />
                      </div>
                    </div>
                  </div>



                  <div v-if="col.type == 'buttons'" class="w-full flex items-center justify-center  ">
                    <div v-for="button in col.buttons" :key="button.action">
                      <button type="button" v-if="checkVisibility(button, row)"
                        :class="`text-${button.color} bg-${button.bgcolor} hover:bg-'+button.bgcolor+'-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800`"
                        @click.stop="onClickButtons($event,button, record)">
                        <i :class="`fa-solid ${button.icon}`" />
                      </button>
                    </div>
                  </div>


                  <div v-if="col.type =='button'" class=" px-2  flex justify-center items-center">
                    <span
                      class2="flex items-center px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      class="bg-primary-100 text-primary-800 text-xs font-medium px-2 py-0.5       rounded dark:bg-primary-900 dark:text-primary-300">
                      <button v-if="checkVisibility(col, record)" type="button"
                        class="flex items-center justify-center px-4 py-1 text-sm font-medium text-white rounded-lg bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-danger-300 dark:bg-primary-600 dark:hover:bg-danger-700 focus:outline-none dark:focus:ring-danger-800"
                        @click="onEditButton(col, record)">
                        <i :class="`fa-solid ${col.buttonIcon} `" />
                        <span v-if="col.action!= 'download'" class=" ml-2">{{ getLabel(col) }}
                        </span>
                      </button>
                    </span>
                  </div>
                  <div v-if="col.type =='indicator'" class="  flex items-center justify-center  ">
                    <span class="flex w-4 h-4 bg-  rounded-full text-center"
                      :class="indicatorColorHandlebar(record,col)" />
                  </div>

                  <div v-if="col.type =='badge'" class="px-2  flex items-center " :class="col.class">
                    <div
                      class="    text-xs text-center    px-2 py-0.5  mr-4     rounded dark:bg-primary-900 dark:text-primary-300">
                      <div class="w-full ">
                        <div
                          class=" w-max  text-xs     text-center  px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border "
                          :class="badgeColorHandlebar(record,col)">
                          <div v-html="displayHandlebarBadge(record,col,true,'data')" />
                        </div>
                      </div>
                    </div>
                  </div>



                  <div v-if="col.type =='issues'" class="px-2  flex items-center " :class="col.class">
                    <div
                      class="    text-xs text-center    px-2 py-0.5  mr-4     rounded dark:bg-primary-900 dark:text-primary-300">
                      <div class="w-full ">
                        <ul class="flex flex-row">
                          <li class="flex flex-row px-1">
                            <div class="bg-red-200 text-red-800 w-6 h-6 p-1 border border-red-500">
                              3
                            </div>
                            <div class="bg-red-700 text-white w-6 h-6 border border-red-800 font-semibold p-1">
                              C
                            </div>
                          </li>
                          <li class="flex flex-row px-1">
                            <div class="bg-orange-200 text-orange-800 w-6 h-6 p-1 border border-orange-500">
                              3
                            </div>
                            <div class="bg-orange-700 text-white w-6 h-6 border border-orange-700 font-semibold p-1">
                              H
                            </div>
                          </li>

                          <li class="flex flex-row px-1">
                            <div class="bg-amber-200 text-amber-800 w-6 h-6 p-1 border border-amber-500">
                              3
                            </div>
                            <div class="bg-amber-600 text-white w-6 h-6 border border-amber-700 font-semibold p-1">
                              M
                            </div>
                          </li>

                          <li class="flex flex-row px-1">
                            <div class="bg-gray-200 text-gray-800 w-6 h-6 p-1 border border-gray-500">
                              5
                            </div>
                            <div class="bg-gray-600 text-white w-6 h-6 border border-gray-600 font-semibold p-1">
                              L
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>


                  <div v-if="col.type =='date-time'" class="px-2   flex items-center">
                    <div
                      class="w-full   text-xs    px-2 py-0.5     mr-4     rounded dark:bg-primary-900 dark:text-primary-300">
                      <div :class="col.style" v-html="displayHandlebarDateTime(record,col,true,'data')" />
                    </div>
                  </div>

                  <div v-if="col.type =='date'" class="px-2   flex items-center">
                    <div
                      class="w-full text-primary-800 text-xs  px-2 py-0.5  mr-4   rounded dark:bg-primary-900 dark:text-primary-300">
                      <div :class="col.style" v-html="displayHandlebarDate(record,col,true,'data')" />
                    </div>
                  </div>

                  <div v-if="col.type =='text'" class="px-2  flex items-center">
                    <div
                      class="w-full bg-primary-100 text-primary-800 text-xs    px-2 py-0.5     mr-4     rounded dark:bg-primary-900 dark:text-primary-300">
                      <div :class="col.style" v-html="displayHandlebarText(record,col,true,'data')" />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="records== undefined && !isLoading3" class="flex items-center px-4 py-2 justify-center"
            style="min-height:200px">
            <div class="flex items-center px-4 py-2 justify-center">
              <span class="text-lg"> <i class="fa-solid fa-info mr-2" />
                {{ common_labels['LABEL_ERROR_NO_ITEMS'][lg] }}</span>
            </div>
          </div>

          <nav v-if="records != undefined" v2-if=" &&  !isLoading3 "
            class=" absolute bottom-0 w-full flex flex-col items-start justify-between p-4 space-y-3 md:flex-row md:items-center md:space-y-0  "
            aria-label="Table navigation">
            <span v-if="((records?.total) > 0)" class="text-sm font-normal text-gray-500 dark:text-gray-400">
              {{ common_labels['LABEL_SHOWING'][lg] }}
              <span class="font-semibold text-gray-900 dark:text-white">
                {{ first_item }}-{{ last_item }}
              </span>
              {{ common_labels['LABEL_OF'][lg] }}
              <span class="font-semibold text-gray-900 dark:text-white">
                {{ records?.total }}
              </span>
            </span>
            <div v-if="total_item > 50">
              <fwb-pagination v-model="currentPage" :total-items="total_item" :per-page="50" show-icons
                previous-label="" next-label="" />
            </div>
          </nav>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
  const MAX_ITEMS = 50;
  import labels from "@/assets/lg/common.json"
  import blank from "@/assets/img/blank.png"
  import Handlebars from "handlebars";
  import { Dropdown } from 'flowbite';
  import { FwbPagination } from 'flowbite-vue'

  export default {
    name: 'TableComponent',

    components: {
      FwbPagination
    },
    props: {
      config: Object,
      records: Object,
      offset: Number,
      limit: Number,
      currentTab: Number,
      isLoading: Boolean,
      isLoading2: Boolean,
      isLoading3: Boolean,
      filterList: Array,
      filters: Object,
      current_page: Number,
      results: Object
    },
    data() {
      return {
        lg: "FR",
        page: 1,
        dropdownSearch: null,
        msg_waiting: {
          "EN": "Please wait a moment",
          "FR": "Merci de patienter"
        },
        people: [],
        selectedPerson: {},
        query: '',
        currentPage: 1,
        selectedItem: '',
        isExtended: false,
        category: null,
        select_values: {},
        start_page: 1,
        selected: [],
        blank: blank,
        globalQuery: '',
        loading2: false,
        total_item: 0,
        common_labels: labels,
        sortColId: null,
        sortOrder: "up",
        sortAttribut: ""


      }
    },


    computed: {
      isBack: function () {
        return (this.$route?.query?.back) || (this.config?.options?.displayBack == true)
      },


      filteredPeople: function () {

        this.$emit("query", this.query)
        //if (this.query === '') return this.people;



        return this.filterList;

        /*
 
       if (this.query === '') return this.people;


       let m = this;
       return this.people.filter(function (person) {
           return person.toLowerCase().includes(m.query.toLowerCase())
       })*/

      },


      first_item: function () {
        //   console.log("***first_item")
        return this.offset + 1;
      },
      last_item: function () {
        //    console.log("***last_item")
        if (this.records == undefined) return 0;

        let n = this.offset + this.limit

        if (n > this.records.total) return this.records.total
        return this.offset + this.limit;
      },
      max_page: function () {
        if (this.records == undefined) return MAX_ITEMS;
        if ((this.records.total % MAX_ITEMS) == 0) return (this.records.total / MAX_ITEMS)
        return parseInt(this.records.total / MAX_ITEMS) + 1;
      },
      displayPagination: function () {
        return (this.max_page > 1)

      },
      previous_valid: function () {
        if (this.current_page == 1) return false;
        return true;
      },
      next_valid: function () {
        if (this.current_page == this.max_page) return false;
        return true;
      },
      tripledots_valid: function () {
        if (this.max_page < 3) return false;
        return true;
      },
    },
    watch: {
      "currentPage": function () {

        this.$emit("refresh", { page: this.currentPage, collection: this.config.id, offset: (this.currentPage - 1) * MAX_ITEMS, limit: MAX_ITEMS, filter: this.filters })

      },
      "records": function () {
        this.total_item = this.records?.total;
      },
      "offset": function () {


        //   console.log("TableComponent ", "watch", "offset", this.offset)
        this.start_page = 1 + this.offset / 10;
      },

      "current_page": function (/*val*/) {
        this.current = this.current_page;
        this.currentPage = this.current_page;
        //  console.log("TableComponent ", "watch", "current_page", this.current_page)
      },
      "selectedItem": function (/*val*/) {


      },

      "$route.query.page": function () {

        //  if (this.$route.query.page != undefined)
        //      this.currentPage = parseInt(this.$route.query.page)
        //  else
        //      this.currentPage = 1

        // this.$emit("refresh", { page: this.currentPage, collection: this.config.id, offset: (this.currentPage - 1) * MAX_ITEMS, limit: MAX_ITEMS, filter: this.filters })

      },
      "$route.query.code": function () {

        //   this.current_page = 1;
        //   this.start_page = 1;
        //   this.max_page = 1;


        this.globalQuery = "";
        this.$emit("refreshPage", { collection: this.config.id, offset: (this.current_page - 1) * MAX_ITEMS, limit: MAX_ITEMS, filter: this.filters, sort: this.sortAttribut, order: this.sortOrder })

      },

      config: function () {


        if (this.config?.tabs != undefined)
          if (this.config?.tabs?.length > 0)
            this.category = this.config?.tabs[this.currentTab].id;



      },
      // A Retirer
      "$route.params.id": function () {
        // this.current_page = 1;



        this.start_page = 1;
        this.globalQuery = "";
        this.category = "";
        if (this.config?.tabs != undefined) {
          if (this.config?.tabs?.length > 0)
            this.category = this.config?.tabs[this.currentTab].id;
        }
      }
    },
    async mounted() {



      Handlebars.registerHelper("fixed2", function (options) {
        //return options.fn(this);

        if (options.fn(this) == undefined) return "0.00"
        if (options.fn(this) == "") return "0.00"
        if (options.fn(this) == "-") return "0.00"
        
        return new Handlebars.SafeString(parseFloat(options.fn(this)).toFixed(2));
      });


      if (this.config.tabs != undefined)
        this.category = this.config.tabs[this.currentTab].id;
      if (this.$route?.query?.q != undefined)
        this.globalQuery = this.$route?.query?.q


      this.current = this.current_page;
      this.currentPage = this.current_page;


      this.start_page = 1 + this.offset / 10;
    },

    methods: {


      onClickMenuButton(button)
      {
        this.$emit("action", button)
      },

      onSelectQuickSearch(e, search) {
        //  console.log("onSelectQuickSearch")
        e.stopPropagation();


        this.globalQuery = search.query[this.lg];

        this.$emit("refresh", { page: 1, collection: this.config.id, offset: 0, limit: MAX_ITEMS, filter: { q: search.query[this.lg] }, sort: this.sortAttribut, order: this.sortOrder })
        this.closeSearchBar()
        this.$nextTick(() => {
          //    console.log("Hide dropDown")
          this.closeSearchBar()
        });
      },
      onSearchSuggestionItemSelected(search) {
        //   console.log("onSearchSuggestionItemSelected")
        this.globalQuery = search.reference

        this.$emit("refresh", { page: 1, collection: this.config.id, offset: 0, limit: MAX_ITEMS, filter: { q: search.reference }, sort: this.sortAttribut, order: this.sortOrder })

        this.$nextTick(() => {
          //    console.log("Hide dropDown")
          this.closeSearchBar()
        });
      },
      onSelectAll() {
        //   console.log("onSelectAll")
        this.closeSearchBar()
      },

      onSearchLastestItemSelected(e, search) {
        //    console.log("onSearchLastestItemSelected")
        //   this.$nextTick(() => {
        e.stopPropagation();
        this.closeSearchBar()
        //   });


        this.globalQuery = search.name


        this.$emit("refresh", { page: 1, collection: this.config.id, offset: 0, limit: MAX_ITEMS, filter: { q: search.name }, sort: this.sortAttribut, order: this.sortOrder })
        return true;

      },

      onSearchItemSelected(e, search) {
        //     console.log("onSearchItemSelected")
        e.stopPropagation();
        this.globalQuery = search.reference

        this.$emit("refresh", { page: 1, collection: this.config.id, offset: 0, limit: MAX_ITEMS, filter: { q: search.name }, sort: this.sortAttribut, order: this.sortOrder })

        this.$nextTick(() => {
          //      console.log("Hide dropDown")
          this.closeSearchBar()
        });
      },

      onEraseSearch(e) {
        //    console.log("onEraseSearch")
        //   e.preventDefault()
        e.stopPropagation();
        this.globalQuery = ""

        this.$emit("refresh", { page: 1, collection: this.config.id, offset: 0, limit: MAX_ITEMS, filter: { q: this.globalQuery }, sort: this.sortAttribut, order: this.sortOrder })

      },

      closeSearchBarKeyup(e) {
        //    console.log("closeSearchBarKeyup", this.globalQuery)
        if (e.key == 'Enter') {
          //   console.log(" closeSearchBarKeyup")
          this.closeSearchBar()

          this.$emit("refresh", { page: 1, collection: this.config.id, offset: 0, limit: MAX_ITEMS, filter: { q: this.globalQuery }, sort: this.sortAttribut, order: this.sortOrder })
          return;
        }

        this.$emit("search", { collection: this.config.id, offset: 0, limit: 5, filter: { q: this.globalQuery } })



        //
      },

      closeSearchBar() {
        //  console.log("closeSearchBar")
        //   console.log(" this.dropdownSearch.hide() 4")
        if (this.dropdownSearch) {
          this.dropdownSearch.hide()
          this.dropdownSearch = null;
        }
      },


      openSearchBar() {
        //   console.log("openSearchBar")
        //   console.log("\topenSearchBar ")
        const options = {
          placement: 'bottom',
          triggerType: 'click',
          offsetSkidding: 0,
          offsetDistance: 0,
          delay: 300,
          onHide: () => {
            //    console.log("dropdownSearch hide")
            return true;
          },
          onShow: () => {
            //    console.log("dropdownSearch show")
            return true;
          },
          onToggle: () => {
            //   console.log("dropdownSearch toggle")
            return true;

          }

        };
        if (this.dropdownSearch == undefined) {
          const $targetEl = document.getElementById("mega-menu-dropdown");
          const $triggerEl = document.getElementById("mega-menu-dropdown-button");
          this.dropdownSearch = new Dropdown($targetEl, $triggerEl, options);


          //     console.log("this.dropdownSearch.show 222")

          //this.dropdownSearch.show()
        }

      }
      ,
      imagePath(item) {

        let addSquare = "?icon=true"
        let path = item?.url

        if (path == undefined) return this.blank

        // if (path.url == undefined) return this.blank

        if (path.indexOf("http") == 0) {
          // return path + "?icon=true";
          return path + addSquare
        }

        if (path.indexOf('data:') == 0) return path;


        return this.host_media + path + addSquare;
        //   return this.host_media + path + "?icon=true&test=1";
      },

      onSearchClose: function () {
        //  console.log("onSearchClose")
        this.isExtended = false;

      },
      onSeachFocus: function (e) {
        //     console.log("onSearchFocus")
        console.log(e)
        this.openSearchBar()

        this.isExtended = true;
        return true;
      },
      onClickDisplayAll: function (e) {
        e.stopPropagation();
        //  console.log("onClickDisplayAll")
        this.dropdownSearch.toggle()
        // this.isExtended = false;

        this.$emit("refresh", { page: 1, collection: this.config.id, offset: 0, limit: MAX_ITEMS, filter: { q: this.globalQuery }, sort: this.sortAttribut, order: this.sortOrder })

      },
      onSearchInput: function (/*key*/) {


        //     console.log("onSearchInput")
        this.isExtended = true;
      },


      displaySeachValue: function (item) {
        //     console.log("displaySeachValue")
        if (item) return item.reference;
        return ''
      },
      OnClickSearch: function () {
        //   console.log("OnClickSearch")
        //  console.log("onClickSearch")
        this.openSearchBar()

        this.isExtended = false;
        return true;
      },

      onBack: function () {

        let url = this.$route.query.back;
        if (this.config && this.config.options && this.config.options.displayBack != false) {
          url = this.config.options.urlBack;
        }
        window.location = url
        // this.$router.push(url)
      },
      getLabel: function (col) {

        if (col?.buttonName != undefined) {
          if (col?.buttonName[this.lg] != undefined)

            return col?.buttonName[this.lg]
        }
        if (col.action == "download") return "Télécharger"; //this.common_labels['LABEL_DOWNLOAD'][this.lg]
        if (col.action == "edit") return this.common_labels['LABEL_EDIT'][this.lg]
        if (col.action == "qrcode") return this.common_labels['LABEL_VIEW_QRCODE'][this.lg]
        if (col.action == "view") return this.common_labels['LABEL_VIEW'][this.lg]
        if (col.action == "template") return this.common_labels['LABEL_EDIT'][this.lg]
        if (col.action == "navigate") return "Voir le produit";
        return "?????"

      },
      onButtonNumber: function (page) {

        let new_page = this.current_page

        if (page == "+") {

          //   let n = "(" +   parseInt(this.max_page) + "=" + (parseInt(this.current_page) +1) + ")/2 = "+ (( parseInt(this.max_page) -  ( parseInt(this.current_page) +1)) /2)
          new_page = this.current_page + parseInt((parseInt(this.max_page) - (parseInt(this.current_page) + 1)) / 2)
          this.start_page = this.current_page


        }
        else
          if (page == "-") {
            new_page = this.current_page - (parseInt(parseInt(this.current_page) / 2))
            this.start_page = this.current_page


          }
          else {



            new_page = page;
            this.start_page = page



          }



        this.$emit("refresh", { page: new_page, collection: this.config.id, offset: (this.new_page - 1) * MAX_ITEMS, limit: MAX_ITEMS, filter: this.filters })


      },
      badgeColor(record, col) {

        let v = this.displayValue(record, col, false, false)
        v = v.toString();
        if (v == '') v = 'default'


        if (col['colors'] == undefined) return 'bg-gray-100 text-gray-800 border-gray-400'


        if (col['colors'][v.toString()] == 'red') return 'font-bold bg-red-100 text-red-800 border-red-400'
        if (col['colors'][v.toString()] == 'orange') return 'font-bold bg-orange-100 text-orange-800 border-orange-400'
        if (col['colors'][v.toString()] == 'green') return 'font-bold bg-green-100 text-green-800 border-green-400'
        return 'font-bold bg-gray-100 text-gray-800 border-gray-400'

      },
      getValueByPath(record, path) {
        try {
          // Split the path into individual components
          const components = path.split(".");
          let value = record;

          // Traverse the nested structure
          for (const component of components) {
            if (component.includes("[")) {
              // Handle list indexing
              const [key, indexStr] = component.split("[");
              const index = parseInt(indexStr.replace("]", ""));
              value = value[key][index];
            } else {
              value = value[component];
            }
          }

          return value;
        } catch (error) {
          return null;
        }
      },
      getImageTitleDataArray(record, col) {


        let records = this.getValueByPath(record, 'items');



        //   console.log(JSON.stringify(records))
        if (Array.isArray(records)) {

          let items = [];

          for (let i = 0; i < records.length; i++) {

            /*
                let title = this.displayHandlebar(record, col, true, 'data');
                            let subtitle = this.displayHandlebar(record, col, true, 'subdata');
            
                            let default_image = col.image_default;
            
            
                            if (default_image == undefined) default_image = blank;
            
                            let url = this.displayHandlebar(record, col, true, 'image_url');
            
            
            
                            if (url == '-') {
            
            
                                url = default_image;
            
                            }
            
            
            */

            //  console.log(JSON.stringify(records))



            let name = this.getValueByPath(records[i], 'supplier.name');
            let image = this.getValueByPath(records[i], 'supplier.image_id');

            let item = {
              title: name,
              subtitle: records[i]['subname'],
              url: "https://files.b4food.io/b4food/images/" + image
            }
            items.push(item)
          }


          /*
                              let components = [];
                              for (let i = 0; i < record[v1].length; i++) {
                                  let url = this.displayHandlebar(record[v1][i], col, true, 'img');
                                  let title = this.displayHandlebar(record[v1][i], col, true, 'data');
                                  let subtitle = this.displayHandlebar(record[v1][i], col, true, 'subdata');
          
          
          
                                  components.push(
                                      {
                                          title: title,
                                          subtitle: subtitle,
                                          url: "https://files.b4food.io/b4food/images/" + url
                                      })
                                      */

          return items
        }
        //  console.log("/////////////////////////////////////////////////////")
        //  console.log(components)



        let title = this.displayHandlebar(record, col, true, 'data');




        let subtitle = this.displayHandlebar(record, col, true, 'subdata');

        let default_image = col.image_default;


        if (default_image == undefined) default_image = blank;

        let url = this.displayHandlebar(record, col, true, 'image_url');



        if (url == '-') {


          url = default_image;

        }


        return [{
          title: title,
          subtitle: subtitle,
          url: "https://files.b4food.io/b4food/images/" + url
        }]

      },
      maskName(name) {
        if (name.length <= 2) {
          // Si le nom est trop court, on retourne le nom tel quel
          return name;
        }

        let middleIndex = Math.floor(name.length / 2);
        let maskedName = '';

        for (let i = 0; i < name.length; i++) {
          if (i === 0 || i === name.length - 1 || i === middleIndex) {
            maskedName += name[i];
          } else {
            maskedName += '*';
          }
        }

        return maskedName;
      },


      getImageTitleData(record, col) {
        if (Array.isArray(record)) {
          let items = [];
          items = [{
            title: "title",
            subtitle: "subtitle",
            url: "https://files.b4food.io/b4food/images/" + "url"
          }]
          return items
        }

        //  console.log("/////////////////////////////////////////////////////")
        //  console.log(components)

        let title = this.displayHandlebar(record, col, true, 'data');
        let subtitle = this.displayHandlebar(record, col, true, 'subdata');

        // title = this.maskName(title)

        let default_image = col.image_default;


        if (default_image == undefined) default_image = blank;

        let url = this.displayHandlebar(record, col, true, 'image_url');

        if (url == '') {


          url = "65b0d1d4be25c66e6b2a929c" //default_image;

        }


        if (url == null) {


          url = "65b0d1d4be25c66e6b2a929c" //default_image;

        }

        if (url == '-') {


          url = "65b0d1d4be25c66e6b2a929c" //default_image;

        }


        //     if (url == "66bc4f1658e85d6ca6753171") url = "66f2c0e6a132d9ef99845262"
        //     if (url == "65fbf60b555bb6bf38f92bc9") url = "65f1dc5745824108ff740144"
        //     if (url == "66fbb00a64c2e428e4c9e0cf") url = "65f1dc7645824108ff740146"
        //     if (url == "66fbb02864c2e428e4c9e0d1") url = "65f1dc8545824108ff740147"




        return [{
          title: title,
          subtitle: subtitle,
          url: "https://files.b4food.io/b4food/images/" + url
        }]

      },
      changeSelect(event, value) {

        let filter = this.filters

        filter[value] = event.target.value

        this.$emit("refreshTable", { value: this.config.id, offset: 0, limit: MAX_ITEMS, filter: filter, sort: this.sortAttribut, order: this.sortOrder })

      },
      selectItemType(value) {


        this.category = value;
        this.globalQuery = "";
        this.$emit("selectTab", value)

      },

      sortCol(col) {
        if (this.sortColId == col.id) {
          if (this.sortOrder == 'up') this.sortOrder = 'down'
          else this.sortOrder = 'up'

          this.$emit("refresh", { collection: this.config.id, offset: (this.current_page), limit: MAX_ITEMS, filter: this.filters, sort: this.sortAttribut, order: this.sortOrder })
          return;
        }

        this.sortOrder = 'down'
        this.sortColId = col.id
        this.sortAttribut = col.sort

        this.$emit("refresh", { collection: this.config.id, offset: (this.current_page), limit: MAX_ITEMS, filter: this.filters, sort: this.sortAttribut, order: this.sortOrder })
      },
      checkVisibility(col, record) {

        if (col.condition != undefined) {

          let show = false;

          for (let i = 0; i < col?.condition?.length; i++) {


            let keys = Object.keys(col.condition[i])
            for (let j = 0; j < keys.length; j++) {
              if (record[keys[j]] == col.condition[i][keys[j]]) {
                show = true;
              }
            }


          }
          return show;
        }

        return true;
      },

      async onEditButton(col, record) {
        event.stopPropagation();

        if (col.action == "template") {
          //    let url = "http://localhost:3000/design/" + record._id
          let url = "https://editor.b4food.io?id=" + record._id

          window.open(url, "template-editor")

          return;
        }


        if (col.action == "navigate") {

          window.location = "/admin/views/products/" + record.product_id + "?back=/admin/views/data-errors"
          return;
        }

        if (col.action == "download") {

          if (col.data == "file_fr.url")
            window.open("https://file-storage-ten.vercel.app/b4food/files/64ad21bb9b5bbf445ad621ab")

          if (col.data == "file.url") {

            let file = record['file'].url;


            this.$emit("download", { file: file, name: record.name })

            // await this.downloadPDF(file, record.name)
            //  let result = this.buildValue(col.data, record, "")
            //  result = result.toString()

            //downloadFile()
          }

          return false;
        }
        if (col.action == "qrcode") {

          return false;
        }

        if (col.buttonURI) {

          const template = Handlebars.compile(col.buttonURI);
          let url = (template(record));


          if (col.backInUrl) {
            url = url + "?back=/admin/views/enrs"

          }
          this.$router.push(url)
          return;
        }


        //  this.$router.push("/admin/flows/" + record.id)
      },
      onClickCheck(event, item) {

        event.stopPropagation();
        this.selected.push(item._id)

      },
      onButtonNext() {

        let page = this.current_page;

        if ((this.current_page + 2) > this.max_page) {
          page = this.max_page;
          this.start_page = page - 2;
        }
        else {
          page = this.current_page + 1;
          this.start_page = page - 1;
        }

        let offset = (page - 1) * MAX_ITEMS
        let filters = this.filters
        if (this.category != undefined) filters['category'] = this.category;
        this.$emit("refresh", { page: this.current_page, collection: this.config.id, offset: offset, limit: MAX_ITEMS, filter: filters, sort: this.sortAttribut, order: this.sortOrder })
      },

      onButtonPrevious() {
        let page = this.current_page;

        if (page < 3) {
          page = 1;
          this.start_page = 1;
        }
        else {
          page = page - 1;
          this.start_page = this.current_page - 1;
        }

        let filters = this.filters
        if (this.category != undefined) filters['category'] = this.category;
        this.$emit("refresh", { page: page, collection: this.config.id, offset: (page - 1) * MAX_ITEMS, limit: MAX_ITEMS, filter: filters, sort: this.sortAttribut, order: this.sortOrder })
      },

      onClickBreadCrumb(/*val*/) {

      },


      onClickButtons(e, event, row) {
        e.preventDefault()
        let url = event.uri

        url = url.replace("{{row._id}}", row._id)

        //salert(JSON.stringify(event.uri))
        if (event.action == "redirect") {
          window.open(url, '_blank');
        }

        return false;
      },

      onClickDelete() {
        this.$emit("delete", { collection: this.config.id, selected: [] })
      },

      onClickDuplicate() {
        if (this.selected?.length == 1)
          this.$emit("duplicate", this.selected[0])
        else
          alert("Error on duplicate")
      },

      onClickRefresh() {

        this.$emit("refresh", { collection: this.config.id, offset: (this.current_page), limit: MAX_ITEMS, filter: this.filters, sort: this.sortAttribut, order: this.sortOrder })

      },
      onClickExport() {
        this.$emit("export", { collection: this.config.id })
      },
      onClickAdd() {
        this.$emit("add", { collection: this.config.id })
      },
      onClickUp() {
        this.$emit("up", { collection: this.config.id })
      },
      onRowClick(record) {

        if (this.config.clickrow == false) return;

        this.$emit("rowClick", { collection: this.config.id, record: record._id, body: record })
      },

      manageVariable(v, d, v_d) {

        if (Array.isArray(v)) {
          let r = ""
          for (let i = 0; i < v.length; i++) {
            r = r + this.manageVariableSub(v[i], d)
          }
          if (r == undefined) return v_d;
          return r;
        }
        let val = this.manageVariableSub(v, d)
        if (val == undefined)
          if (v_d != undefined) return v_d
          else ""
        return val
      },
      manageVariableSub(v/*, d*/) {

        if (v == undefined) {
          return v;
        }
        if (v[0] == '@') {
          // if (d == undefined)  {}
          let a = v.replace('@', '')
          try {

            let p = 'd.' + a;

            let e = eval(p);

            if (e == undefined) e = undefined
            return e;
          }
          catch (e) {

            return '-'
          }
        }
        return v
      },
      buildValue(format, record, s) {

        for (let i = 0; i < format?.length; i++) {

          let el = format[i]
          let newclass = el.class;

          if (el.mapping) {
            let m = this.manageVariable(el.data, record);
            newclass = newclass + " " + el.mapping[m].class;
          }
          let tag = el.tag
          if (tag == undefined) tag = "span"

          if (newclass == undefined) newclass = ""

          s = s + '<' + tag + ' class="' + newclass + '">'

          if (el.children) {
            s = s + this.buildValue(el.children, record, s)
          }
          else
            if (el.mapping) {
              let m = this.manageVariable(el.data, record);

              if (el.mapping[m])
                s = s + el.mapping[m][this.lg]
              else
                s = s + m
            }
            else {

              let v = this.manageVariable(el.data, record, el.default)

              s = s + v
            }

          s = s + '</' + tag + '>'
        }


        return s;
      },
      buildAvatar(v1, v2, record) {
        console.log(v1, v2, record)
        return "***************"
        /*  if (Array.isArray(record[v1])) {
              return v1;
          }
          return v1;*/
        /*
                        let a1 = this.manageVariable(v1, record);
                        let a2 = this.manageVariable(v2, record);
        
                        if ((a1 == undefined) || (a1 == ""))
                            a1 = "";
                        else
                            a1 = a1[0].toUpperCase();
        
                        if ((a2 == undefined) || (a2 == ""))
                            a2 = "";
                        else
                            a2 = a2[0].toUpperCase();
        
                        if (a1 == "" && a2 == "") a1 = "-";
        
                        return a1 + a2;*/
      },
      displayHandlebarText(record, col, map, att) {

        let visible = this.displayHandlebar(record, col, map, 'visible')
        if (visible == null) return ""
        if (visible == "null") return ""
        if (visible == "-") return ""
        if (visible == "--") return ""
        let title = this.displayHandlebar(record, col, map, att)



        return title

      },
      displayHandlebarBadge(record, col, map, att) {
        if (col[att] == undefined) return "";


        const template = Handlebars.compile(col[att]);
        let label = (template(record));

        //Voir pour le defaut
        if (label == "") {

          if (col.default == undefined) return null
          if (col.default[this.lg] == undefined) return null
          return col.default[this.lg]
        }

        if (map == false) return label;

        if (col.map == undefined) return label.toString();
        if (col.map[this.lg] == undefined) return label.toString();
        if (col.map[this.lg][label] == undefined) return label.toString();
        //  return col.map[this.lg][label].toString();

        return col.map[this.lg][label]
      },
      displayHandlebar(record, col, map, att) {

        if (col[att] == undefined) return "";
        const template = Handlebars.compile(col[att]);
        let label = (template(record));

        //Voir pour le defaut
        if (label == "") {

          if (col.default == undefined) return null
          if (col.default[this.lg] == undefined) return null
          return col.default[this.lg]
        }

        if (map == false) return label;

        if (col.map == undefined) return label.toString();
        if (col.map[this.lg] == undefined) return label.toString();
        if (col.map[this.lg][label] == undefined) return label.toString();
        //  return col.map[this.lg][label].toString();

        return col.map[this.lg][label]

      },
      displayHandlebarDateTime(record, col, map, att) {
        let date = this.displayHandlebar(record, col, map, att)
        if (date == undefined) return "";
        if (date == "") return "";
        if (date == "null") return "";
        if (date == "--") return "";

        var myDate = new Date(date);

        const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        let formatteddate = myDate.toLocaleString('fr-FR', options);

        const optionsHour = { hour: 'numeric', minute: 'numeric', second: 'numeric' };
        let formatteddateHour = myDate.toLocaleString('fr-FR', optionsHour);
        //  return date
        return formatteddate + "  <small>" + formatteddateHour + "</small>"

        /*
                       
        
                        let d = new Date(date)
                        let m = ("0" + (d.getMonth() + 1)).slice(-2)
                        let day = ("0" + (d.getDay() + 1)).slice(-2)
                        let hour = ("0" + (d.getHours() + 1)).slice(-2)
                        let minute = ("0" + (d.getMinutes() + 1)).slice(-2)
                        if (d.getFullYear() == undefined) return ""
        
                        if (m == undefined) return ""
        
                        if (day == undefined) return ""
        
        
                        return (day + "/" + m + "/" + d.getFullYear() + " à " + hour + ":" + minute);
        
        */



      },
      displayHandlebarDate(record, col, map, att) {


        let date = this.displayHandlebar(record, col, map, att)
        if (date == undefined) return "";
        if (date == "") return "";
        let a = date.split("T")

        let b = a[0].split("-");

        return b[2] + "/" + b[1] + "/" + b[0]


      },
      indicatorColorHandlebar(record, col) {

        let v = this.displayHandlebar(record, col, false, 'data')
        v = v.toString();
        if (v == '') v = 'default'


        if (col['colors'] == undefined) return 'bg-gray-300'
        if (col['colors'][v.toString()] == 'purple') return '  bg-purple-300'


        if (col['colors'][v.toString()] == 'red') return 'bg-red-500 '
        if (col['colors'][v.toString()] == 'orange') return ' bg-orange-500'
        if (col['colors'][v.toString()] == 'green') return 'bg-lime-500 '
        return ' bg-gray-300 '

      },
      badgeColorHandlebar(record, col) {

        let v = this.displayHandlebar(record, col, false, 'data')
        if (v == undefined) return 'bg-gray-100 text-gray-800 border-gray-400'
        v = v.toString();
        if (v == '') v = 'default'


        if (col['colors'] == undefined) return 'bg-gray-100 text-gray-800 border-gray-400'
        if (col['colors'][v.toString()] == 'purple') return 'font-bold bg-purple-100 text-purple-800 border-purple-400'


        if (col['colors'][v.toString()] == 'red') return 'font-bold bg-red-100 text-red-800 border-red-400'
        if (col['colors'][v.toString()] == 'orange') return 'font-bold bg-orange-100 text-orange-800 border-orange-400'
        if (col['colors'][v.toString()] == 'green') return 'font-bold bg-green-100 text-green-800 border-green-400'
        return 'font-bold bg-gray-100 text-gray-800 border-gray-400'

      },
      displayMappedValue(record, col) {
        let v = this.displayValue(record, col, false)
        return v;
      },
      displayValue(record, col, simple = false, map = true) {


        if (col.format != undefined) {
          let result = this.buildValue(col.format, record, "")
          result = result.toString()
          return result;
          //A revoir car il y a le formattage, a faire avant
          //    if (map == false) return result;
          //    if (col.map == undefined) return result;
          //    if (col.map[this.lg] == undefined) return result;
          //    if (col.map[this.lg][result] == undefined) return result;
          //    return col.map[this.lg][result].toString();

        }

        if (record[col.data] == null) {
          if (col.default != undefined) {

            if (map == false) return "";
            if (col.default[this.lg] == undefined) return col.default.toString();

            return col.default[this.lg].toString();
          }



          if (simple == true)
            return "<span class='text-red-900 font-bold'><i class='fa-solid fa-triangle-exclamation mr-2'></i>Info non disponible</span>"
          else
            return " - "

        }

        if (col.type == "date-time") {
          if (record[col.data] == undefined) return "";
          var myDate = new Date(record[col.data]);

          const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
          let date = myDate.toLocaleString('fr-FR', options);
          //  return date
          return record[col.data] + " " + myDate + " " + date
        }

        if (col.type == "date") {


          let date = record[col.data].split("T")
          if (date.length != 2) {
            date = record[col.data].split(" ")
          }
          return date[0];
        }


        if (map == false) return record[col.data];

        if (col.map == undefined) return record[col.data].toString();
        if (col.map[this.lg] == undefined) return record[col.data].toString();
        if (col.map[this.lg][record[col.data]] == undefined) return record[col.data].toString();
        return col.map[this.lg][record[col.data]].toString();

      }
    }
  }
</script>