<template>
  <div class="mt-2 mb-2 w-full bg-gray-100 flex flex-row items-center relative">
    <div class="px-3   w-full flex flex-col justify-start items-center">
      <div
        v-for="(record,index) in records"
        :key="record.id"
        class="w-full flex flex-col items-center justify-start   "
      >
        <div class=" w-full flex flex-row items-center text-xl   border-b border-gray-600   mt-6">
          <div class="text-left  w-full text-xl  font-bold">
            {{ record.name }}
          </div>
          <div class=" px-3  text-left font-bold   ">
            <div class="flex flex-row items-center ">
              <label
                for="default"
                class="  text-left block  pr-3  text-sm   text-gray-900 dark:text-white"
                :class="{'font-bold': output[index]!=true, ' font-medium': output[index]==true, }"
              >
                Non
              </label>
              <label class="  w-auto relative inline-flex items-center mt-2  mb-2 cursor-pointer">
                <input
                  :id="`${id}-switch-${{index}} `"
                  v-model="output[index]"
                  type="checkbox"
                  class="sr-only peer"
                  @change="onChangeParent(index, record?.items?.length)"
                >
                <div
                  class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
                />
                <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300" />
              </label>

              <label
                for="default"
                class="  text-left block  pr-3  text-sm   text-gray-900 dark:text-white"
                :class="{'font-bold': output[index]==true, ' font-medium': output[index]!=true, }"
              >
                Oui
              </label>
            </div>
          </div>
        </div>

        <div
          v-for="(row,index2) in record.items"
          :key="row.id"
          class="pl-12  w-full flex flex-row items-center justify-start bg-white "
        >
          <div class="w-full text-left font-bold px-3 flex flex-row">
            <div class="w-96 text-left font-bold px-3  ">
              {{ row.code }}
            </div>

            <div class=" text-left font-bold px-3 w-full">
              {{ row.name }}
            </div>

            <div class=" text-left font-normal text-gray-700 px-3 w-full tuncate">
              {{ row.subname }}
            </div>
          </div>

          <div class="     text-left font-bold px-3 ">
            <div class="flex flex-row items-center ">
              <label
                for="default"
                class="  text-left block  pr-3  text-sm   text-gray-900 dark:text-white"
                :class="{'font-bold': output[index2]!=true, ' font-medium': output[index2]==true, }"
              >
                Non
              </label>
              <label class="  w-auto relative inline-flex items-center mt-2  mb-2 cursor-pointer">
                <input
                  :id="`${id}-switch-${{index2}}-${{index}}`"
                  v-model="output[1000 + index*1000 + index2] "
                  type="checkbox"
                  value=""
                  class="sr-only peer"
                  @change="onChangeItem(index, index2, record?.items?.length)"
                >
                <div
                  class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
                />
                <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300" />
              </label>

              <label
                for="default"
                class="  text-left block  pr-3  text-sm   text-gray-900 dark:text-white"
                :class="{'font-bold': output[index2]==true, ' font-medium': output[index2]!=true, }"
              >
                Oui
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
    import { mapGetters } from "vuex";
    import service from "@/services/FullService.vue"
    export default {
        name: 'ContentTableSwitch',
        props: {
            id: String,
            label: String,
            placeholder: String,
            required: Boolean,
            config: Object,
            value: Array
        },
        computed: {
            ...mapGetters({ user: "StateUser" }),
            isLoggedIn: function () {
                return this.$store.getters.isAuthenticated;
            }
        },
        watch: {
            value: function () {
                //  this.output = val;
            }
        },
        async mounted() {

            let data = await this.getRecords();

            this.records = this.transformData(data) 

            for (let index = 0; index < this.records.length; index++) {

                this.output[index] = false;


                for (let index2 = 0; index2 < this.records[index].items.length; index2++) {
                    if (this.value != undefined)

                        this.output[1000 + index * 1000 + index2] = this.value.includes(this.records[index].items[index2].id);

                }
            }



            //this.output = this.value;

        },
        methods: {
            async getRecords() {
                let url = "data-sources/66b71a6844009b613badde07/records"
                let types = await service.getData(url, this.user?.token, { limit: 300 })
                //  console.log(JSON.stringify(types))
                return types

            },
            onChangeParent(index, count) {

                for (let i = 0; i < count; i++)
                    this.output[1000 + index * 1000 + i] = this.output[index]

            },
            onChangeItem(parent, index, count) {
                console.log(index)
                console.log(parent)
                console.log(count)
                let allTrue = true
                for (let i = 0; i < count; i++) {
                    console.log(1000 + parent * 1000 + i, this.output[1000 + parent * 1000 + i])
                    if (!this.output[1000 + parent * 1000 + i]) allTrue = false;
                }


                this.output[parent] = allTrue

            },

            getData() {

                let permissions = []

                for (let index = 0; index < this.records.length; index++) {
                    for (let index2 = 0; index2 < this.records[index].items.length; index2++) {
                        if (this.output[1000 + index * 1000 + index2]) permissions.push(this.records[index].items[index2].id)
                    }
                }
                return permissions;

            },
            transformData(data) {
                let records = [];

                for (let i = 0; i < data?.records?.length; i++) {

                    let cat = records.find(function (a) { return a.name == data.records[i].category })
                    if (cat == undefined) {
                        let y = records.push(
                            {
                                "id": data.records[i].category,
                                "name": data.records[i].category,
                                "items": []
                            })
                     //   console.log(">>>>>>>>>>", y)

                        cat = records[y - 1]
                    }

                    cat?.items?.push({
                        id: data.records[i]._id,
                        code: data.records[i].type,
                        name: data.records[i].title,
                        subname: data.records[i].subTitle,
                        permissions: data.records[i].permissions,
                    })
                }
                return records;
            }
        },

        data() {
            return {
                output: [],
                records: []
            }
        }
    }
</script>