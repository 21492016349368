<template>
  <div class="mb-4   relative overflow-y-auto" :class="config.class">
    <div class="w-full flex flex-row items-center  ">

      <label v-if="records != undefined && (configTable?.options?.show_total != false)" :for="`${id}`"
        class="py-2 w-full text-left block  text-sm font-bold text-gray-900 dark:text-white">
        Total : {{ getTotal }}
      </label>

      <div v-for="bt in configTable?.buttons" :key="bt.id">
        <button type="button"
          class="mb-2 flex items-center justify-left px-4 py-2 text-sm font-medium text-white rounded-lg bg-gray-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
          @click="onClickButtonBar(bt)">
          {{ bt?.label && bt?.label[lg] }}
        </button>
      </div>

      <label :for="`${id}`" class="hidden w-full text-left block mb-2 text-sm font-bold text-gray-900 dark:text-white">
        {{ label }}
      </label>
    </div>

    <div v-if="isButton" class="w-full inline-flex justify-start  items-center">
      <input :id="`${id}`" v-model="output" type="text"
        class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm   focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        :placeholder="placeholder" :required="required">

      <button type="button"
        class="  ml-2 flex items-center justify-center flex-shrink-0 px-3 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
        @click="onClickExport">
        <i class="fa-solid fa-ok mr-2 " />
        {{ common_labels['BUTTON_CHANGE'][lg] }}
      </button>
    </div>

    <div class="overflow-x-auto relative min-h-96" :class="config.table_class">
      <div v-if="localrecords == undefined" class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        Information non disponible
      </div>



      <table v-if="(localrecords != undefined) "
        class="bg-white w-full text-sm text-left text-gray-500 dark:text-gray-400   ">
        <thead class="text-xs text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th v-for="col in columns" :key="col.id" scope="col" :class="col.styleh" class="px-4 py-3">
              {{ col.name[lg] }}
            </th>
          </tr>
        </thead>

        <tbody v-if="config?.mode == 'list'" class="w-full">

          <template v-for="(row ) in localrecords" :key="row.id">

            <tr class="border-b dark:border-gray-700">
              <td colspan="2" class="  px-4 py-2 bg-gray-300 text-gray-800 font-bold">
                {{row.delivery_number}}
                <span class="text-sm font-medium text-gray-600 ml-2">Réception du {{formatDate(row.received_at)}}</span>
              </td>

              <td class="  px-4 py-2 bg-gray-300 text-gray-800 font-bold">
                <div class="w-full text-right text-base">

                  {{row?.weight_gross?.value}} {{row?.weight_gross?.unit}}
                </div>

              </td>
              <td class="  px-4 py-2 bg-gray-300 text-gray-800 font-bold">
                &nbsp;
              </td>

              <td class="  px-4 py-2 bg-gray-300 text-gray-800 font-bold">
                <div class="w-full text-right text-base">
                  {{row.weight_net?.value}} {{row.weight_net?.unit}}
                </div>
              </td>
              <td class="  px-4 py-2 bg-gray-300 text-gray-800 font-bold">
                &nbsp;
              </td>
              <td class="  px-4 py-2 bg-gray-300 text-gray-800 font-bold">
                <div class="w-full text-right text-base">
                  {{row.amount}} {{row?.currency}}
                </div>
              </td>
              <td class="  px-4 py-2 bg-gray-300 text-gray-800 font-bold">
                &nbsp;
              </td>
              <td class="  px-4 py-2 bg-gray-300 text-gray-800 font-bold">
                &nbsp;
              </td>
            </tr>

            <tr v-for="(subrow ) in row.items" :key="subrow.id" class="border-b dark:border-gray-700">
              <td v-for="col in columns" :key="col.id" class="  px-4 py-3">
                <div
                  v-if="col.type != 'buttons' && col.type != 'dropdown' && col.type != 'input' && col.type != 'input-inline'  && col.type != 'check'"
                  class="w-full" :class="col.style" v-html="getValue(col, subrow)" />
              </td>
            </tr>

          </template>


        </tbody>
        <tbody v-if="config?.mode != 'list'">
          <tr v-for="(row,index) in localrecords" :key="row.id" class="border-b dark:border-gray-700">



            <td v-for="col in columns" :key="col.id" class="  px-4 py-3">
              <div
                v-if="col.type != 'buttons' && col.type != 'dropdown' && col.type != 'input' && col.type != 'input-inline'  && col.type != 'check'"
                class="w-full" :class="col.style" v-html="getValue(col, row)" />

              <div v-if="col.type=='check'" class="flex items-center">
                <input id="checkbox-all" type="checkbox"
                  class="w-4 h-4   border-gray-300 rounded text-primary-600 focus:ring-primary-500    focus:ring-2  ">
                <label for="checkbox-all" class="sr-only" />
                <div class="ml-2 w-full" :class="col.style" v-html="getValue(col, row)" />
              </div>

              <div v-if="col.type == 'buttons'" class="w-full flex items-center justify-center  ">
                <div v-for="button in col.buttons" :key="button.action">
                  <button type="button" v-if="checkVisibility(button, row)"
                    :class="`text-${button.color} bg-${button.bgcolor} hover:bg-'+button.bgcolor+'-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800`"
                    @click="onClickButton(button, row)">
                    <i :class="`fa-solid ${button.icon}`" />
                  </button>
                </div>
              </div>

              <div v-if="col.type == 'dropdown'" class="w-full flex items-center justify-center z-30 ">
                <button :id="`dropdownButton-${row._id}`" @click="openDropdown(row._id)"
                  class="inline-flex items-center p-0.5 text-sm font-medium text-center text-gray-500 hover:text-gray-800 rounded-lg focus:outline-none dark:text-gray-400 dark:hover:text-gray-100"
                  type="button">
                  <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewbox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
                  </svg>
                </button>
                <div :id="`dropdownMenu-${row._id}`"
                  class="hidden z-10 w-56 bg-white border-gray-200 border rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600">


                  <ul class="py-1 text-sm text-gray-700 dark:text-gray-200">
                    <li class="border-b border-gray-200" v-for="menu_item in col.actions_middle" :key="menu_item.action"
                      :class="showMenu(row, menu_item)">
                      <div @click="onMenu(menu_item.action, row)"
                        class="cursor-pointer font-semibold  block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                        {{menu_item.label[lg]}}
                      </div>
                    </li>

                   

                   
                 
                  
                  </ul>
                  <div class="py-1">
                    <div @click="onMenu(menu_item.action, row)"  v-for="menu_item in col.actions_bottom" :key="menu_item.action"
                      class="cursor-pointer font-semibold block py-2 px-4 text-sm text-red-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">
                      {{menu_item.label[lg]}}
                    </div>
                  </div>
                   

                </div>


              </div>


              <div v-if="(col.type == 'input') && (col.format=='dynamic')"
                class="w-full flex-col flex items-center justify-center  ">

                <ContentInput :id="`content-text-input-${id}-${row?._id}-${col?.id}`"
                  :ref="`content-text-input-${id}-${row?._id}`" :config="buildItem(col, row)" :index="index.toString()"
                  :label="item?.label && item.label[lg]" :is-button="item?.isButton"
                  :placeholder="item?.placeholder[lg]" :value="buildValue(col,row)" :format="buildFormat(col,row)"
                  :dunit="buildUnit(col, row)" @update-field="configFieldChange" @value-changed="onValueChanged"
                  @manage-field="manageField" />
             </div>
 
              <div v-if="(col.type == 'input') && ( col.format!='date'  && col.format!='dynamic' )"
                class="w-full flex-col flex items-center justify-center  ">

                <ContentInput :id="`content-text-input-${id}-${row?._id}-${col?.id}`"
                  :ref="`content-text-input-${id}-${row?._id}`" :config="buildItem(col, row)" :index="index.toString()"
                  :label="item?.label && item.label[lg]" :is-button="item?.isButton"
                  :placeholder="item?.placeholder[lg]" :value="buildValue(col,row)" :format="buildFormat(col,row)"
                  :dunit="buildUnit(col, row)" @update-field="configFieldChange" @value-changed="onValueChanged"
                  @manage-field="manageField" />




              </div>

              <div v-if="col.type == 'input-inline' && col.format!='date'"
                class="w-full flex-row flex items-center justify-center bg-yellow-100 ">


                <ContentInlineEditor :id="`content-text-input2-${id}-${row?._id}-${col?.id}`"
                  :ref="`content-text-input2-${id}-${row?._id}`" :config="buildItem(col, row)" :index="index.toString()"
                  :label="item?.label && item.label[lg]" :is-button="item?.isButton"
                  :placeholder="item?.placeholder[lg]" :value="buildValue(col,row)" :format="buildFormat(col,row)"
                  :dunit="buildUnit(col, row)" @update-field="configFieldChange" @value-changed="onValueChanged"
                  @manage-field="manageField" isDeveloper="true" />


              </div>

              <div v-if="col.type == 'switch'" class="w-full flex items-center justify-center  ">
                <ContentSwitch :id="`content-text-input-${id}-${row?._id}-${col?.id}`"
                  :ref="`content-text-input-${id}-${row?._id}`" :config="buildItem(col,row)" :index="index.toString()"
                  :label="item?.label && item.label[lg]" :is-button="item?.isButton"
                  :placeholder="item?.placeholder[lg]" :value="buildValue(col,row)" :format="buildFormat(col,row)"
                  :dunit="buildUnit(col, row)" @update-field="configFieldChange" @value-changed="onValueChanged"
                  @manage-field="manageField" />
              </div>

              <div v-if="col.type == 'select'" class="w-full flex items-center justify-center  ">
                <ContentSelectCollection :ref="`tiny-content-selectCollection--${id}-${row?._id}-${col?.id}`"
                  :custom-action="customAction" :label="item.label && item.label[lg]" :datasource="col.datasource_id"
                  :mode="item.mode" :record="row" :filters="item.filters" :config="item" :value="row[item.data]"
                  :choices="samples" @manage-field="manageField" @value-changed="onValueChanged" />
              </div>

              <div v-if="col.type == 'input' && col.format=='date'" class="w-full flex  items-center justify-center  ">
                <ContentDate :id="`content-text-input-${id}-${row?._id}-${col?.id}`"
                  :ref="`content-text-input-${id}-${row?._id}`" :config="buildItem(col,row)" :index="index.toString()"
                  :label="item?.label && item.label[lg]" :is-button="item?.isButton"
                  :placeholder="item?.placeholder[lg]" :value="buildValue(col,row)" @update-field="configFieldChange"
                  @value-changed="onValueChanged" @manage-field="manageField" />
              </div>
 
            </td>
          </tr>
        </tbody>
      </table>

    </div>
 
  </div>

</template>
<script>
  import common_labels from "@/assets/lg/common.json"
  import Handlebars from "handlebars";
  import ContentSwitch from '@/components/content/ContentSwitch.vue'
  import ContentInput from '@/components/content/ContentInput.vue'
  import ContentDate from '@/components/content/ContentDate.vue'
  import ContentSelectCollection from '@/components/content/ContentSelectCollection.vue'
  import ContentInlineEditor from '@/components/content/ContentInlineEditor.vue'

  import { Dropdown } from 'flowbite';
  import engine from "@/lib/engine.js"

  export default {
    name: 'ContentTinyTable',
    components: { ContentInput, ContentDate, ContentSwitch, ContentSelectCollection, ContentInlineEditor },
    props: {
      id: String,
      label: String,
      model: String,
      required: Boolean,
      records: Object,
      values: Object,
      config: Object,
      configTable: Object
    },
    data() {
      return {
        output: "",
        items: [],
        dropdowns: {},
        localrecords: [],
        customAction: null,
        lg: "FR",
        common_labels: common_labels,
        columns: [],
        item:
        {
          "id": "c7f97ce9-8781-4248-9ce6-4d985ccf7179",
          "label": {
            "EN": null,
            "FR": null
          },
          "placeholder": {
            "EN": null,
            "FR": null
          },
          "type": "text",
          "format": "number",
          "data": "reference",
          "class": "w-6/12"
        }
      }
    },
    computed: {

      isButtonAdd: function () {
        return true
      },
      isButton: function () {
        return false
      },
      getTotal: function () {


        if (this.localrecords == undefined) return "";
        //if (this.model == 'product')
        //   return this.records.reduce((acc, curr) => acc + parseInt(curr.accepted), 0)

        return this.localrecords?.length
        //return this.records.reduce((acc, curr) => acc + parseInt(curr.quantity), 0)
      }
    },
    watch: {

      'configTable?.cols': function () {
        this.columns = this.configTable?.cols?.filter(function (item) { return item.type !== 'hidden' })


      },
      'configTable': function () {
        this.columns = this.configTable?.cols?.filter(function (item) { return item.type !== 'hidden' })


      },
      'records': function () {
      //  console.log("##################", this.records.length)


        //PV CHANGE
        //this.localrecords =this.records

        if (this.config?.local_filter == "m_1")
          this.localrecords = this.records.filter(item => (item?.quantity?.value !== undefined));
        else
          this.localrecords = this.records

        this.refreshTable()
      },
      //  'values': function () {
      //   this.refreshTable()
      //  }

    },
    mounted() {




      Handlebars.registerHelper("fixed2", function (options) {
        //return options.fn(this);

        if (options.fn(this) == undefined) return "0.00"
        if (options.fn(this) == "") return "0.00"
        if (options.fn(this) == "-") return "0.00"
        
        return new Handlebars.SafeString(parseFloat(options.fn(this)).toFixed(2));
      });


      Handlebars.registerHelper("dateFormat", function (options) {
        //return options.fn(this);

        if (options.fn(this) == undefined) return "-"
        if (options.fn(this) == "") return "-"
        if (options.fn(this) == "-") return "-"
        let d = new Date(options.fn(this))
        let m = ("0" + (d.getMonth() + 1)).slice(-2)
        let day = ("0" + (d.getDate())).slice(-2)


        return new Handlebars.SafeString(day + "/" + m + "/" + d.getFullYear());
      });



      Handlebars.registerHelper("datetimeFormat", function (options) {
        //return options.fn(this);

        if (options.fn(this) == undefined) return ""
        if (options.fn(this) == "") return ""
        let d = new Date(options.fn(this))
        let m = ("0" + (d.getMonth() + 1)).slice(-2)
        let day = ("0" + (d.getDay() + 1)).slice(-2)
        let hour = ("0" + (d.getHours() + 1)).slice(-2)
        let minute = ("0" + (d.getMinutes() + 1)).slice(-2)

        return new Handlebars.SafeString(day + "/" + m + "/" + d.getFullYear() + " à " + hour + ":" + minute);



      });


      this.columns = this.configTable?.cols?.filter(function (item) { return item.type !== 'hidden' })


      //PV CHANGE
      //this.localrecords =this.records
      if (this.config?.local_filter == "m_1")

        this.localrecords = this.records.filter(item => (item?.quantity?.value !== undefined));
      else
        this.localrecords = this.records
      this.refreshTable()

    },
    methods: {


      showMenu(item, menu) {
        if (menu?.hidden == undefined) return ""
        console.log("menu?.hidden=", menu?.hidden, item.batch_number)
        let e = new engine(item, menu?.hidden)
        let c = e.check()
        console.log("c=", c)
        console.log()
        if (c.length != 0) {

          this.choices = []  //TODO : Mettre la valeur de la régle

          console.log("c.length =", c.length)
          return "hidden"; //c.length;
        }
        return "";
      },

      formatNumberTwoDigits(n) {
        if (parseInt(n) > 9) return n

        return "0" + n
      },
      formatDate(date) {
        if (date == undefined) return ""
        if (date == "") return ""

        let s = date.split("T")
        let g = s[0].split("-")
        return g[2] + "/" + this.formatNumberTwoDigits(g[1]) + "/" + this.formatNumberTwoDigits(g[0])
      },

      openDropdown(id) {

        //     if (this.dropdowns[id] != undefined) {
        //       this.dropdowns[id].show()
        //       return
        //     }
        // set the dropdown menu element
        const $targetEl = document.getElementById('dropdownMenu-' + id);

        // set the element that trigger the dropdown menu on click
        const $triggerEl = document.getElementById('dropdownButton-' + id);

        // options with default values
        const options = {
          placement: 'bottom',
          triggerType: 'click',
          offsetSkidding: 0,
          offsetDistance: 10,
          delay: 300,
          ignoreClickOutsideClass: false,
          onHide: () => {
            console.log('dropdown has been hidden');
          },
          onShow: () => {
            console.log('dropdown has been shown');
          },
          onToggle: () => {
            console.log('dropdown has been toggled');
          },
        };

        // instance options object
        const instanceOptions = {
          id: 'dropdownMenu-' + id,
          override: true
        };

        this.dropdowns[id] = new Dropdown($targetEl, $triggerEl, options, instanceOptions);

        this.dropdowns[id].show()
      },

      checkVisibility(button, data) {
        console.log(JSON.stringify(button))
        console.log(button.condition == undefined)
        if (button?.condition == undefined) return true;
        if (data?.nemrod_photos?.length > 0) return true
        if (data?.veto_photos?.length > 0) return true

        return false

      },
      refreshTable() {

        if (this.values == undefined) {
          this.items = []
          //this.items = this.localrecords
          return;
        }
        this.items = []

        for (let i = 0; i < this.values?.length; i++) {

          let val = this.values[i]
          let index = this.localrecords.findIndex(function (a) { return a._id == val._id })

          if (index != -1) {
           // console.log(">>>>>>> FIND")
            this.items.push(val)

            this.localrecords[index]['price_with_tax'] = val['price_with_tax']
            // this.items[index]['price_with_tax'] = val['price_with_tax']
          }
        //  else console.log(">>>>>>> NOT FIND")

          //price_with_tax
        }

      },



      buildItem: function (col, row) {

        //  console.log("buildItem", row, col)
        return {
          "id": row?._id + "c7f97ce9-8781-4248-9ce6-4d985ccf7179-" + col.id,
          "label": {
            "EN": null,
            "FR": null //row?._id + "c7f97ce9-8781-4248-9ce6-4d985ccf7179" + col.id
          },
          "placeholder": {
            "EN": null,
            "FR": null
          },
          "type": "text",

          "prefix": col.prefix,
          "suffix": col.suffix,

          "format": col.format, //? col.format : "mass",
          "data": col?.attribut,
          "class": "w-6/12"
        }
      },
      configFieldChange: function () {
        console.log("configFieldChange")
      },
      manageField: function () {
        console.log("manageField")
      },
      formatFloat(n) {
        if (n == undefined) return 0.0
        if (n == "") return 0.0

        if (typeof n == "string") {
          n = n.replaceAll(",", ".")
          n = parseFloat(n)
        }

        return n
      },
      refreshValues(item) {
        if ((item?.attribute != 'unit_price') && (item?.attribute != 'quantity')) return;

        if (this.localrecords[item.index]['unit_price'] == undefined) {
          this.localrecords[item.index]['price_with_tax'] = { unit: "€", value: "" }
          return
        }

        if (this.localrecords[item.index]['quantity'] == undefined) {
          this.localrecords[item.index]['price_with_tax'] = { unit: "€", value: "" }
          return
        }
        let p = this.formatFloat(this.localrecords[item.index]['unit_price'].value || 0) * this.formatFloat(this.localrecords[item.index].quantity?.value || 0)
        //console.log(JSON.stringify(item))
        this.localrecords[item.index]['price_with_tax'] = { unit: "€", value: this.formatFloat(p)  }

      },
      onValueChanged: function (item) {




        // console.log("onValueChanged ---item---------------------------------------", item.id)
        // console.log(JSON.stringify(item))
        //console.log(JSON.stringify(this.localrecords[item.index]))

        //this.localrecords[item.index][item.id] = item.value
        let _id = this.localrecords[item.index]._id

        //  console.log("onValueChanged ---this.localrecords[item.index]._id---------------------------------------", item.id)
        //  console.log(_id)

        //  console.log("onValueChanged ---this.items---------------------------------------", item.id)
        //  console.log(JSON.stringify(this.items))

        let itemIndex = this.items.findIndex(function (a) { console.log(a); return a._id == _id })
        //  console.log("itemIndex", itemIndex)


        // console.log("onValueChanged ---this.localrecords---------------------------------------", item.id)
        // console.log(JSON.stringify(this.localrecords))


        if (itemIndex == -1) {

          console.log("INDEX NOT FOUND")

          let newItem2 = this.localrecords[item.index];
          newItem2[item.attribute] = item.value_full;
          this.items.push(newItem2)

        }
        else {
          // console.log("INDEX  FOUND")
          //console.log(JSON.stringify(this.localrecords[item.index]))
          // console.log(JSON.stringify(this.items))

          // console.log(item.attribute)

          if (this.items[itemIndex][item.attribute]?.value == undefined) {
            this.items[itemIndex][item.attribute] = item.value_full;
          }
          else {
            //  this.items[itemIndex][item.attribute].value = item.value;
            this.items[itemIndex][item.attribute] = item.value_full;
          }




        }

        this.refreshValues(item);

        /*
        if (itemIndex == -1) {

          console.log(JSON.stringify(item))
          console.log(item.id, " - ", this.localrecords[item.index])
     
          // newitem[item.id] = item.value;

          let newitem = this.localrecords[item.index]
          console.log("New item --------------------")
          console.log(newitem)
          newitem.index = item.index
          // if ( newitem[ item.id])  
          newitem[item.id] = item.value
          // newitem[ item.id].value  = item.value

          this.items.push(newitem)
        }
        else {
          //                  console.log("+++++++-------------------------------------")
                 this.items[itemIndex][item.id] = item.value;


                 this.localrecords[item.index][item.id]= item.value;


          //                  console.log(JSON.stringify(this.items[itemIndex]))
          //                  console.log("+++++++-------------------------------------")
          //   this.items[itemIndex].quantity.value = item.value

          //   this.items[item.id].value =  item.value;
        }
          */

        //                console.log("-------------------------------------")
        //                console.log(JSON.stringify(this.items))
        //                console.log("-------------------------------------")
      },


      buildValue: function (col, data) {

      //  console.log("########### ", col.data, JSON.stringify(data))
 
        let value = this.getValue(col, data)
      //  console.log("########### value", value)
      //  console.log(this.id, "buildValue", data.unit)
        return { value: value, unit: this.buildUnit(col, data), sign: null };
      },
      buildFormat: function (col, data) {

        if (data == undefined) return

        if (col.format == "dynamic") {
          if (data.product_packaging_type == "unit") {
            return "uvc"
          }
        }
        if (col.format == "currency") {
          return col.format
        }


        if (col.unit == "rule1") {
          if (data.product_packaging_type == "raw") {
            return "mass"
          }

        }


        if (col.format == "mass") {
          return col.format
        }
        if ((data.unit != undefined) && (col.unit != undefined)) { return "famous" }
        return null;
      },
      buildUnit: function (col, data) {
        if (data == undefined) return
        if (data.unit == "") data.unit = null


        if (col.unit == "rule1") {

          if (data.product_packaging_type == "raw") {
            return "kg"
          }

          if (data.product_packaging_type == "unit") {
            return "uvc"
          }


          if (data.product_packaging_type == "bundle") {
            return "pack"
          }

          if (data.unit != undefined) {
            return data.unit.unit
          }


        }

        return col.unit
      },
      buildUnitOld: function (col, data) {

        if (data == undefined) return
        if (data.unit == "") data.unit = null


        if (col.unit == "rule1") {

          if (data.product_packaging_type == "raw") {
            return "kg"
          }

          if (data.product_packaging_type == "unit") {
            return "uvc"
          }


          if (data.product_packaging_type == "bundle") {
            return "pack"
          }

          if (data.unit != undefined) {
            return data.unit.unit
          }


        }

        // console.log(this.id, "buildFormat", data.unit)

        if ((data.unit != undefined) && (col.unit != undefined)) {
          return data.unit.unit
        }


        return col.unit ? col.unit : null;
      },









      onMenu: function (action, item) {

        let event =
        {
          id: this.id,
          data: item,
          action: action
        }

        this.$emit("manageButtonClick", event)
        this.dropdowns[item._id].hide()
      },

      onClickButton: function (button, item) {
        //  console.log(item)


        let url = button.uri;

        const template = Handlebars.compile(url);
        url = (template({ row: item }));
        let event =
        {
          id: this.id,
          data: item,
          action: button.action,
          properties: { url: url }
        }
        this.$emit("manageButtonClick", event)

      },
      onClickButtonBar: function (button) {

        let event =
        {
          id: null,
          data: null,
          action: button.action
        }


        this.$emit("manageButtonClick", event)
      },


      formatDateTimeDisplay(t) {
        if (t == undefined) return "";
        if (t == "") return "";

        let myDate = new Date(t)


        const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        let formatteddate = myDate.toLocaleString('fr-FR', options);

        const optionsHour = { hour: 'numeric', minute: 'numeric', second: 'numeric' };
        let formatteddateHour = myDate.toLocaleString('fr-FR', optionsHour);
        //  return date
        return formatteddate + "  <small>" + formatteddateHour + "</small>"


      },
      formatDateDisplay(t) {
        if (t == undefined) return "";
        if (t == "") return "";

        let myDate = new Date(t)


        const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        let formatteddate = myDate.toLocaleString('fr-FR', options);


        //  return date
        return formatteddate


      },

      getValue(col, record) {
        if (col.data == undefined) return null
        const template = Handlebars.compile(col.data);
        let value = (template(record));
        let displayValue = value;


        if (col?.map != undefined) {
          if (col?.map[this.lg] != undefined) {
            if (col?.map[this.lg][value] != undefined) {
              displayValue = col?.map[this.lg][value]
            }
          }
        }

        if (col.type == "text") return displayValue;
        if (col.type == "date") return this.formatDateDisplay(displayValue);
        if (col.type == "date-time") return this.formatDateTimeDisplay(displayValue);
        if (col.type == "time") return displayValue;

        if (col.type == "badge") {

          let color = "gray"
          if (col?.colors != undefined)
            if (col?.colors[value] != undefined) {
              color = col?.colors[value]
            }
          let html = '<span class="bg-' + color + '-100 text-' + color + '-800 border border-' + color + '-400 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 ">' + displayValue + '</span>';
          return html;
        }

        return value
      },
      getData() {


        for (let i = 0; i < this.localrecords.length; i++) {

          if (this.localrecords[i]['price_with_tax'] == undefined) {
            this.localrecords[i]['price_with_tax'] = { value: "0", unit: "€" }
          }

        }





        return this.localrecords

        // return this.items

        // return this.records
      }
    }
  }
</script>
<style>
  blockquote {
    background: #eee;
    border-radius: 5px;
    margin: 16px 0;
  }

  blockquote p {
    padding: 15px;
  }

  cite {
    margin: 16px 32px;
    font-weight: bold;
  }

  blockquote p::before {
    content: '\201C';
  }

  blockquote p::after {
    content: '\201D';
  }

  [contenteditable='true'] {
    caret-color: red;
  }
</style>