<template>
  <div id="create-purchase-item-modal" tabindex="-1" aria-hidden="true"
    class="fixed top-0 left-0 right-0 z-50 items-center justify-center hidden w-full overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full">
    <div class="relative w-full h-full max-w-2xl p-4 md:h-auto">
      <!-- Modal content -->
      <div class="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
        <!-- Modal header -->
        <div
          class="flex items-center justify-between pb-4 mb-4 border-b border-gray-200 rounded-t sm:mb-5 dark:border-gray-700">
          <h3 class="font-semibold text-gray-900 dark:text-white"  v-if="product_id != undefined">
            {{ labels['TITLE_PURCHASE_ITEM'][lg] }} --{{ id }}
          </h3>
          <h3 class="font-semibold text-gray-900 dark:text-white" v-if="product_id == undefined"> 
            {{ labels['TITLE_PURCHASE_ITEM_NEW'][lg] }}  
          </h3>
          <button type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 inline-flex dark:hover:bg-gray-600 dark:hover:text-white"
            @click=" onClose()">
            <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd" />
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <div class="grid gap-4 mb-4 sm:grid-cols-1 sm:gap-6 sm:mb-5">
          <div class="w-full">
            <p class="text-sm font-normal text-gray-500 dark:text-gray-400">
              {{ labels['TITLE_CONTACT_DESCRIPTION'][lg] }}
            </p>







       


            <div class="mt-2 w-full    flex">



              <div class=" w-full  text-left justify-start mr-2 flex flex-row items-center py-2">
                <label for="game_specie_id" class=" pr-3  block   text-sm font-semibold text-gray-900 dark:text-white">
                  {{labels['TITLE_PRODUCT'][lg]}} </label>
                <select id="hunting-item-nemrod_comment_id" v-model="product_id"
                  class="w-full  bg-gray-50 border border-gray-300 text-gray-900    text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block   p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                  <option v-for="product in products" :key="product._id" :value="product._id">
                    {{ product?.name?.toUpperCase() }}
                  </option>
                </select>
              </div>
            </div>
          

            <div class="mt-2 w-full    flex flex-row">
              <div class=" w-full flex flex-row items-center font-bold text-left justify-left mr-2">
                <label for="item_quantity" class="block mb-2 text-sm  pr-4  text-gray-900 dark:text-white">
                  {{ labels['TITLE_QUANTITY'][lg] }}</label>
                <input id="item_quantity" v-model="item_quantity" v-focus type="email" name="email"
                  class="w-36 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-primary-600 focus:border-primary-600 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="">
              </div>
              <div class=" w-full flex flex-row items-center font-bold text-left justify-left mr-2">
                <label for="item_quantity" class="block mb-2 text-sm  pr-4  text-gray-900 dark:text-white">
                  {{ labels['TITLE_PRICE'][lg] }}</label>
                <input id="item_quantity" v-model="item_quantity" v-focus type="email" name="email"
                  class="w-36 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-primary-600 focus:border-primary-600 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="">
              </div>
            </div>

            <div class="mt-4 w-full  text-left justify-left">
              <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                {{ labels['TITLE_CONTACT_ROLE'][lg] }}</label>
              <input id="role" v-model="role" v-focus type="text" name="role"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="">
            </div>
          </div>
        </div>

        <div class="flex items-center justify-end">
          <button type="button"
            class="mr-3 py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
            @click=" onClose()">
            {{ labels['TITLE_CONTACT_CANCEL'][lg] }}
          </button>
          <button type="button"
            class="inline-flex items-center text-white bg-gray-600 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-500 dark:hover:bg-gray-600 dark:focus:ring-gray-900"
            @click="onValidate">
            {{ labels['TITLE_CONTACT_OK'][lg] }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import labels from "@/assets/lg/purchase-item.json"

  export default {
    name: 'ContactManagementModal',
    components: {

    },
    props: {
      input: Object
    },
    data() {
      return ({
        lg: "FR",
        labels: labels,
        firstname: "",
        lastname: "",
        role: "",
        email: "",
        phone: "",
        mobile: "",
        products: [],
        comment: "",
        id: null,



        transparent: false,
        item: {
          id: "1",
          label: {
            'EN': "",
            'FR': ''
          }
        },
      })
    },
    watch: {
      'input': function () {

        this.products = this.filterProducts(this.input?.source.items)
        this.product_id = this.input?.product_id

        this.id = this.input?.id
        this.firstname = this.input?.firstname
        this.lastname = this.input?.lastname
        this.phone = this.input?.phone
        this.mobile = this.input?.mobile
        this.email = this.input?.email
        this.role = this.input?.role
        this.comment = this.input?.comment
      },
    },

    methods: {

      filterProducts(items) {
        let localrecords = items.filter(item => (item?.quantity?.value === undefined));
        return localrecords
      },



      onValidate() {

        let item =
        {
          id: this.id,
          firstname: this.firstname,
          lastname: this.lastname,
          phone: this.phone,
          mobile: this.mobile,
          email: this.email,
          role: this.role,
          comment: this.comment
        }


        this.$emit("confirm", item)

        // this.name = ""
      },

      onClose() {
        this.$emit("close")
        //  this.name = ""
      }
    },
  }
</script>