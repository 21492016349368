<template>
  <section class="bg-gray-50 dark:bg-gray-900 py-3 sm:py-5">
    <div class="mx-auto   lg:pr-6">
      <div class="relative overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">

        <div
          class="flex flex-col pl-4 pr-12 py-3 space-y-3 lg:flex-row lg:items-center lg:justify-between lg:space-y-0 lg:space-x-4">
          <div class="flex items-center flex-1 space-x-4">
            <div class="flex items-center divide-x divide-gray-300 dark:divide-gray-700">
              <div class="pr-3">
                <a class="inline-flex justify-center p-1 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  @click="onCancel">
                  <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                      clip-rule="evenodd" />
                  </svg>
                </a>
              </div>
              <div class="flex pl-0 space-x-2 sm:px-2">
                <h2
                  class="pr-6  pl-2 text-left   text-3xl tracking-tight font-bold text-gray-900 dark:text-white inline-flex">
                  {{ title }}
                  <h4 class="  px-8 text-left  text-xl tracking-tight font-semibold text-gray-600 dark:text-white">
                    {{ subtitle }}
                  </h4>
                </h2>
                <a href="#"
                  class="hidden inline-flex justify-center p-1 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                  <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                      clip-rule="evenodd" />
                  </svg>
                </a>
                <a href="#"
                  class="hidden inline-flex justify-center p-1 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                  <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                      clip-rule="evenodd" />
                  </svg>
                </a>
                <a href="#"
                  class="hidden inline-flex justify-center p-1 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                  <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z" />
                    <path fill-rule="evenodd"
                      d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
                      clip-rule="evenodd" />
                  </svg>
                </a>
                <a href="#"
                  class="hidden inline-flex justify-center p-1 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                  <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                  </svg>
                </a>
              </div>
              <div class="pl-3 text-sm font-medium text-gray-500">
                -
              </div>
            </div>

            <h5 class="hidden">
              <span class="text-gray-500"> bel_total </span>
              <span class="ml-1 dark:text-white font-bold"> total </span>
            </h5>
            <h5 class="hidden">
              <span class="text-gray-500">Total sales:</span>
              <span class="dark:text-white">$88.4k</span>
            </h5>

            <button v-if="(template && template.options &&template.options.displayGenerate) && (id != 'new-item')"
              type="button"
              class="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-gray-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
              @click="onGenerateSheet('eu-fr')">
              {{ common_labels['BUTTON_GENERATE_SHEET'][lg] }}
            </button>

            <button v-if="(template && template.options &&template.options.displayGenerate) && (id != 'new-item')"
              type="button"
              class="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-gray-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
              @click="onGenerateSheet('eu-en')">
              {{ common_labels['BUTTON_GENERATE_SHEET_EU_EN'][lg] }}
            </button>
            <button v-if="(template && template.options &&template.options.displayGenerate) && (id != 'new-item')"
              type="button"
              class="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-gray-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
              @click="onGenerateSheet('us-en')">
              {{ common_labels['BUTTON_GENERATE_SHEET_US'][lg] }}
            </button>
            <button v-if="(template && template.options &&template.options.displayGenerate) && (id != 'new-item')"
              type="button"
              class="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-gray-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
              @click="onGenerateSheet('au-en')">
              {{ common_labels['BUTTON_GENERATE_SHEET_AU'][lg] }}
            </button>

            <button v-if="(template && template.options &&template.options.displayView) && (id != 'new-item')"
              type="button"
              class="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-gray-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
              @click="onViewSheet">
              {{ common_labels['BUTTON_VIEW_SHEET'][lg] }}
            </button>
          </div>

          <div
            class="flex  flex-col flex-shrink-0 space-y-3 md:flex-row md:items-center lg:justify-end md:space-y-0 md:space-x-3">
            <button v-if="(template && template.options &&template.options.displayWizard) " type="button"
              class="w-36 flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg hover:bg-primary-800 focus:ring-4 focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"
              :class="{'bg-gray-300 pointer-events-none': currentTab==0,'bg-gray-700 ': currentTab!=0 }"
              @click="onPreviousTab">
              <i class="fa-solid fa-chevron-left mr-2" /> {{ common_labels['BUTTON_PREVIOUS'][lg] }}
            </button>

            <button v-if="(template && template.options &&template.options.displayWizard) " type="button"
              class="w-36 flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-gray-800"
              :class="{'hidden': currentTab==(template?.children?.length-1), }" @click="onNextTab">
              {{ common_labels['BUTTON_NEXT'][lg] }} <i class="ml-2 fa-solid fa-chevron-right" />
            </button>



            <button v-if="(template && template.options && (template.options.displayWizard==true))" type="button"
              class="w-36 flex items-center justify-center px-4 py-2 text-sm font-semibold text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
              :class="{'hidden': currentTab!=(template?.children?.length-1), }" @click="onSaveSheet">
              {{ common_labels['BUTTON_SAVE'][lg] }}
            </button>
          </div>
        </div>

        <div class="w-full  px-8 p-4 border-t-2 border-t-gray-300">
          <ol class="  items-center w-full space-y-4 sm:flex sm:space-x-8 sm:space-y-0  ">
            <li v-for="(tab,index) in template.children" :key="tab.id"
              class="flex items-center space-x-2.5 w-full min-w-sm"
              :class="{'text-blue-600 dark:text-blue-500 font-bold pointer-events-none' : (currentTab == index), 'text-gray-500 dark:text-gray-400 cursor-pointer': (currentTab != index)}"
              @click="onSelectTab(tab,index)">
              <span class="flex items-center justify-center w-8 h-8 border   rounded-full shrink-0 "
                :class="{'border-blue-600  ' : (currentTab == index), 'border-gray-500  ': (currentTab != index)}">
                {{ index + 1 }}
              </span>
              <span>
                <h3 class=" leading-tight"> {{ tab.title[lg] }} </h3>
                <p class="text-sm hidden font-medium">Step details here </p>
              </span>
            </li>
          </ol>
        </div>
        <div class2="min-h-500" style="min-height:400px">
          <div v-if="template != undefined" :id="`#myFormTabContent-${id}`">
            <div v-for="(tab,index) in template.children" :id="`tab-content-${tab.id}`" :key="tab.id"
              class=" p-0 rounded-lg bg-gray-100 dark:bg-gray-800"
              :class="{'hidden': (currentTab != index), 'block': (currentTab != index) }" role="tabpanel"
              :aria-labelledby="`tab-${tab.id}`">
              <B4FContent v-for="body in tab.body" :id="`sheet-${tab.id}`" :ref="`sheet-${tab.id}`" :key="body.id"
                :custom-action="customAction" :config="body" :record="record" :settings="tab" :countries="countries"
                @change-view="onChangeView" @select-image-modal-open="OnSelectImageModalOpen"
                @select-image-modal-close="OnSelectImageModalClose" @value-changed="onValueChanged" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Main modal -->
    <div id="delete-confirm-modal-w" tabindex="-1" aria-hidden="true"
      class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full">
      <div class="relative p-4 w-full max-w-md h-full md:h-auto">
        <!-- Modal content -->
        <div class="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
          <button type="button"
            class="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
            @click="onDeleteCancel">
            <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd" />
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
          <svg class="text-gray-400 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto" aria-hidden="true" fill="currentColor"
            viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
              clip-rule="evenodd" />
          </svg>
          <p class="mb-4 text-gray-500 dark:text-gray-300">
            ** {{ message_confirmed }} **
          </p>
          <div class="flex justify-center items-center space-x-4">
            <button type="button"
              class="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
              @click="onDeleteCancel">
              Non
            </button>
            <button
              class="py-2 px-3 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900"
              @click="onDeleteConfirmed">
              Oui
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

  import engine from "@/lib/engine.js"




  import common_labels from "@/assets/lg/common.json"
  import B4FContent from '@/components/ContentComponent.vue'
  import { Modal } from 'flowbite';
  export default {
    name: 'WizardComponent',
    components: {
      B4FContent
    },
    props: {
      config: Object,
      record: Object,
      template: Object,


      customAction: Object,
      title: String,
      countries: [],
      subtitle: String
    },
    data() {
      return {
        id: null,
        currentTab: 0,
        deleteConfirmModal: null,
        common_labels: common_labels,
        lg: "FR",
        tabs: null,
        tabElements: [],
        message_confirmed: "???",
        timeout: null,
        options: {
          defaultTabId: 'information',
          activeClasses: 'text-blue-600 hover:text-blue-600 dark:text-blue-500 dark:hover:text-blue-400 border-blue-600 dark:border-blue-500',
          inactiveClasses: 'text-gray-500 hover:text-gray-600 dark:text-gray-400 border-gray-100 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300',
          //onShow: () => {

          //}
        }
      }

    },
    watch: {
      record: async function () {


      },
      template: async function () {
        this.index = 0;
        this.$nextTick(() => {
          this.loadTabs();
          const tab = document.getElementById('tab-content-home');
          if (tab) tab.classList.remove("hidden");
          this.currentTab = 0;
          //    this.timeout = setTimeout(this.loadTabs(), 13000);
        });

      }
    },
    mounted() {

      this.id = this.record?._id;

      if (this.id == undefined) this.id = "new-item";

      if (this.template != undefined) {
        if (this.template.children != undefined) {

          this.timeout = setTimeout(this.loadTabs(), 13000);
        }

      }
      const modalEl = document.getElementById('delete-confirm-modal-w');
      this.deleteConfirmModal = new Modal(modalEl, {
        placement: 'center'
      });

      this.$nextTick(() => {
        const tab = document.getElementById('tab-content-home');
        if (tab) tab.classList.remove("hidden");
        this.currentTab = 0;
        //    this.timeout = setTimeout(this.loadTabs(), 13000);
      });
    },
    methods: {
      onPreviousTab: function () {
        if (this.currentTab != 0)
          this.currentTab = this.currentTab - 1
      },
      onNextTab: function () {
        //  console.log("TEMPLATES --------------------------------------------")
        //  console.log(JSON.stringify(this.template.children[this.currentTab].controls))
        const formrules = this.template.children[this.currentTab].controls;
        let e = new engine(this.record, formrules)
        let c = e.check()
        if (c.length > 0) {
          return;
        }
        if (this.currentTab != (this.template.children.length - 1))
          this.currentTab = this.currentTab + 1
      },
      onSelectTab: function (tab, n) {
        console.log(tab)
        console.log(n)
        this.currentTab = n
      },
      onValueChanged: function (event) {
        this.$emit("valueChanged", event)

        if (event.action == "validate") {
          this.onNextTab()
        }

      },
      loadTabs2: function () { },
      loadTabs: function () {
        clearTimeout(this.timeout);
        if (this.template != undefined && this.template.children != undefined)
          for (let i = 0; i < this.template?.children?.length; i++) {
            let child = this.template.children[i];


            if (document.querySelector('#tab-' + child.id) != undefined)
              this.tabElements.push(
                {
                  id: child.id,
                  triggerEl: document.querySelector('#tab-' + child.id),
                  targetEl: document.querySelector('#tab-content-' + child.id)
                }

              )
          }


        //this.options.defaultTabId = this.tabElements[0].id;


        // open tab item based on id
        //if (this.tabElements[0].id != undefined) {

        //    this.$nextTick(function () {



        // if ()
        // this.tabs.show(this.tabElements[0].id);
        //   });

        //}

      },
      OnSelectImageModalClose() {

      },
      onChangeView(item) {
        this.$emit("changeView", item)
      },
      onCancel() {
        this.$emit("cancel")
      },
      OnSelectImageModalOpen: function (item) {
        this.$emit('selectImageModalOpen', item)
      },
      async onDeleteConfirmed() {
        await this.$emit("delete", this.record);
        this.deleteConfirmModal.hide();
      },
      onDeleteCancel() {

        this.deleteConfirmModal.hide();
      },
      onDeleteSheet() {
        this.message_confirmed = "Etes vous sûr de supprimer cette élement ?"
        this.deleteConfirmModal.show();

      },
      onArchiveSheet() {
        this.message_confirmed = "Etes vous sûr d'archiver cette fiche ?\nElle ne sera plus accesible pour vos clients."
        this.deleteConfirmModal.show();
      },
      onViewSheet() {
        //  this.message_confirmed = "Etes vous sûr d'archiver cette fiche ?\nElle ne sera plus accesible pour vos clients."
        //  this.deleteConfirmModal.show();

        //this.onGenerateSheet(this.record.market + "-" + this.record.langage)

        this.$emit("display", { id: this.record.id })

      },
      onSaveSheet() {


        let record = {}

        for (let i = 0; i < this.template.children?.length; i++) {
          let tab = this.template.children[i].id;

          for (let i = 0; i < this.$refs['sheet-' + tab]?.length; i++) {

          
            let data = this.$refs['sheet-' + tab][i].getData();
  

            if (data.isError == true) {
          
              alert("Le champ '" + data.label + "'' est obligatoire")

              return;
            }

            //   console.log("TAB ", tab, "SAVE")
            //   console.log(JSON.stringify(data))

            record = { ...record, ...data.data }
          }

        }

        console.log("SAVE SAVE SAVE SAVE SAVE SAVE ------------------------------------")
        console.log(record)



  this.$emit("save", record)
      },
      onGenerateSheet(market) {
        this.$emit("generate", { id: this.record.id, market: market })
      }
    },
  }
</script>