<template>
  <nav
    class="fixed top-0 z-10 w-full bg-white border-b border-gray-200 bg-gray-800  dark:bg-gray-800 dark:border-gray-700 rounded-r-6 mr-5"
  >
    <div class="relative px-3 py-0 lg:px-5 lg:pl-3">
      <div class="flex items-center justify-start  ">
        <div class="flex items-center justify-start mr-6">
          <div class="flex ml-2 flex items-center justify-start">
            <i
              class="cursor-pointer fas fa-arrow-left mr-3   text-gray-800"
              @click="onBack"
            />

            <img
              :src="logo"
              class="h-16 mr-6"
              alt="B4food.io"
            >
            <span
              class="self-center text-md text-gray-800 font-medium sm:text-2xl whitespace-nowrap dark:text-white"
            >
              <span class="font-semibold">{{ name }}</span>  
              <span
                v-if="reference !=''"
                class="ml-1 font-medium text-lg"
              >({{ reference }})</span><br>
                             
              <span
                v-if="version !='' && status!=''"
                class="ml-2 font-medium text-xs justify-start flex"
              >Version
                {{ version }} <span
                  v-if="status == 'DRAFT'"
                  class="text-orange"
                > (brouillon)</span>
              </span>
            </span>
          </div>
        </div>





        <div class="   w-full  flex  justify-end ">
          <div class="flex  items-center justify-start">
            <ul
              class="grid w-full gap-2 grid-cols-5    py-2"
              :class="{ 'opacity-0': loading, 'opacity-1': !loading}"
            >
              <li>
                <input
                  id="view-1page"
                  type="radio"
                  name="view"
                  value="view-1"
                  class="hidden peer"
                  required
                >
                <label
                  for="view-1page"
                  class="inline-flex items-center justify-center w-full p-2   border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500    hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                  :class="{'text-gray-900 bg-gray-100': (viewBy==2), 'text-white bg-blue-900': (viewBy==1) }"
                  @click="onClickViewBy(1)"
                >

                  1 Page
                </label>
              </li>
              <li>
                <input
                  id="view-2pages"
                  type="radio"
                  name="view"
                  value="view-2"
                  class="hidden peer"
                >
                <label
                  for="view-2pages"
                  class="inline-flex items-center justify-center w-full p-2   border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500     hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                  :class="{'text-gray-900 bg-gray-100': (viewBy==1), 'text-white bg-blue-900': (viewBy==2) }"
                  @click="onClickViewBy(2)"
                >

                  2 Pages
                </label>
              </li>
              <li class="" />
              <li
                :class="{'flex' : (isAvailableEN && isAvailableFR), 'hidden' : !(isAvailableEN && isAvailableFR)} "
                @click="onClickLg(1)"
              >
                <input
                  id="lg-fr"
                  type="radio"
                  name="lg"
                  value="fr"
                  class="hidden peer"
                  required
                >
                <label
                  for="lg-fr"
                  class="inline-flex items-center justify-center w-full   py-1  border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                  :class="{'text-gray-900 bg-gray-100': (selectlg==2), 'text-white bg-blue-900': (selectlg==1) }"
                >

                  <img
                    :src="french"
                    class="h-8 w-8"
                    alt="B4food.io"
                  >
                </label>
              </li>
              <li
                :class="{'flex' : (isAvailableEN && isAvailableFR), 'hidden' : !(isAvailableEN && isAvailableFR)} "
                @click="onClickLg(2)"
              >
                <input
                  id="lg-en"
                  type="radio"
                  name="lg"
                  value="en"
                  class="hidden peer"
                >
                <label
                  for="lg-en"
                  class="inline-flex items-center justify-center w-full py-1   border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                  :class="{'text-gray-900 bg-gray-100': (selectlg==1), 'text-white bg-blue-900': (selectlg==2) }"
                >

                  <img
                    :src="english"
                    class="h-8 w-8"
                    alt="B4food.io"
                  >
                </label>
              </li>
            </ul>
          </div>
        </div>



        <div class="flex items-center">
          <div class="flex items-center ml-3">
            <div class="flex items-center ml-3">
              <button
                id="pdf-download-button"
                aria-expanded="false"
                :class="{ 'opacity-0': loading, 'opacity-1': !loading}"
                class=" text-gray-800 font-bold bg-white flex flex-row items-center  focus:ring-2 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-1.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                type="button"
                @click="onClickDownload"
              >
                Télécharger
              </button>

              <div class="w-6  h-full border-r border-gray-200" />
              <button
                id="pdf-publish-button"
                aria-expanded="false"
                :disabled="mode=='view'"
                :class="{ 'opacity-0': (loading || (mode=='view')), 'opacity-1': (!loading), 'bg-red-800 text-white' : (status=='DRAFT'), 'bg-gray-200 text-gray-400':status=='PUBLISHED' }"
                class="ml-2   font-bold   flex flex-row items-center  focus:ring-2 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-1.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                type="button"
                @click="onClickPublish"
              >
                Publier
              </button>

              <div
                v-if="mode=='view'"
                class="flex flex-row text-white "
              >
                <div
                  class="mr-2 cursor-pointer hover:text-gray-400"
                  @click="goPrevious"
                >
                  <i class="fa-solid fa-chevron-left" />
                </div>

                <div
                  class="cursor-pointer mr-2 hover:text-gray-400"
                  @click="goNext"
                >
                  <i class="fa-solid fa-chevron-right" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>


<script>

    import page from "@/assets/img/page.svg"
    import pages from "@/assets/img/2pages.svg"
    import french from "@/assets/img/french.svg"
    import english from "@/assets/img/english.svg"

    import logo from "@/assets/logo.png"
    import { mapGetters } from "vuex";


    import labels from "@/assets/lg/common.json"
    //import service from "@/services/FullService.vue"
    export default {
        name: 'PDFNavBar',
        props: {
            name: String,
            reference: String,
            status: String,
            profile: String,
            source: String,
            mode: String,
            loading: Boolean,
            version: String,
            isAvailableFR : Boolean,
            isAvailableEN : Boolean
        },
        async mounted() {



            if (this.user == undefined) {

                this.$router.push("/login");
                return;
            }


            this.type = this.profile;

        },
        computed: {
            ...mapGetters({ user: "StateUser" }),
            isLoggedIn: function () {
                return this.$store.getters.isAuthenticated;
            },
        },
        watch:
        {
            //'profile': function (a) {
           
                //this.type = this.profile
            //},
            //'type': function (a) {
           
                // this.$emit("change", this.type)
           // }
        },
        methods: {

            goPrevious: function () {
                this.$emit('previous')
            },
            goNext: function () {
                this.$emit('next')
            },


            onBack: function () {
                this.$emit('back')
            },
            onClickViewBy: function (index) {

              
                this.viewBy = index;
                this.$emit('view', this.viewBy)
            },

            onClickLg: function (index) {



                this.selectlg = index
                this.$emit('lg', index)
            },



            onClickDownload: function () {
                this.$emit('download')
            },
            onClickPublish: function () {

                this.$emit('publish')
            }

        },
        components: {},
        data: function () {
            return {
                viewBy: 1,
                common_labels: labels,
                lg: "FR",
                logo: logo,
                page: page,
                pages: pages,

                french: french,
                english: english,
                selectlg: 1,
                type: 'FR'

            }
        }
    }
</script>