<template>
  <div class="w-full   bg-gray-50 overflow-y-auto"
    :class="{ 'w-48' :(items?.length < 2), 'w-64' :(items?.length ==2), 'w-96' :(items?.length > 2), }">
    <div class="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700">
      <h3 class="font-bold text-gray-900 dark:text-white">
        Détails
      </h3>
    </div>
    <div class="px-2 py-2 flex flex-col w-full border-b border-b-gray-300" v-for="cell in items" :key="cell.id">
      <div class=" text-sm font-normal  h-full flex flex-row items-center px-1 ">

        <div class="flex justify-center w-10 pr-2 ">
          <i class="fa-solid fa-hashtag  " />
        </div>
  
        <span>Numéro de lot 
          :</span>&MediumSpace;<span class="font-semibold">{{ cell?.batch_number }}</span>
      </div>
      <div v-if="cell?.best_before != undefined" class=" text-sm font-normal  h-full flex flex-row items-center px-1 ">

        <div class="flex justify-center w-10 pr-2 ">
          <i class="fa-regular fa-calendar-days" />
        </div>

        <span>DDM
          :</span>&MediumSpace;<span class="font-semibold">{{ formatDateTime(cell?.best_before) }}</span>
      </div>
     
      <div v-if="cell?.use_by_date != undefined" class=" text-sm font-normal  h-full flex flex-row items-center px-1 ">

        <div class="flex justify-center w-10 pr-2 ">
          <i class="fa-regular fa-calendar-days " />
        </div>

        <span>DLC
          :</span>&MediumSpace;<span class="font-semibold">{{ formatDateTime(cell?.use_by_date) }}</span>
      </div>

      <div class=" text-sm font-normal  h-full flex flex-row items-center px-1  mt-2 ">

        <div class="flex justify-center w-10 pr-2 ">
          <i class="fa-solid fa-folder"></i>
        </div>

        <span>Numéro de commande
          :</span>&MediumSpace;<span class="font-semibold">{{ cell?.order_number }}</span>
      </div>



      <div class=" text-sm  font-normal  h-full flex flex-row items-center px-1  ">
        <div class="flex justify-center w-10 pr-2 ">
          <i class="fa-solid fa-boxes-stacked " />
        </div>
        <span>Quantité reçu :</span>&MediumSpace;<span class="font-semibold">{{ cell?.delivery_uvc_count.value }} {{ cell?.delivery_uvc_count.unit }}</span>
      </div>

      <div class=" text-sm   font-normal h-full flex flex-row items-center px-1 ">
        <div class="flex justify-center w-10 pr-2 ">
          <i class="fa-solid fa-temperature-quarter " />
        </div>


        <span>Température
          :</span>&MediumSpace;<span class="font-semibold">{{ cell?.temperature.value }} {{ cell?.temperature.unit }}</span>
      </div>

      <div class="text-sm font-normal mt-2 flex flex-row items-center px-1  h-full">
        <div class="flex justify-center w-10 pr-2 ">
          <i class="fa-solid fa-user  " />
        </div>
        <span>Réceptionné par
          :</span>&MediumSpace;<span class="font-semibold">{{ cell?.done_by?.name }}</span>
      </div>
    </div>
  </div>

</template>

<script>

  export default {
    name: 'FileColDisplayBatch',

    components: {

    },
    methods:
    {
      formatDateTime: function (date) {
        if (date == undefined) return "";
        if (date == "") return "";
        if (date == "null") return "";
        if (date == "--") return "";

        var myDate = new Date(date);

        const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        let formatteddate = myDate.toLocaleString('fr-FR', options);

        //  return date
        return formatteddate
      }
    },
    props: {
      items: Array
    },
    data() {
      return {}
    }
  }

</script>