<template>
    <div :id="`popover-hover-button-${id}-${rank}-${index2}`" data-popover-trigger="hover"
        class="cursor-pointer border border-gray-300 text-left  flex flex-row items-center  h-24  hover:border-2 hover:border-gray-400"
        @mouseover="onHoverPopup(rank,index2,cell[week])">



        <div class="w-full text-sm font-semibold   h-full flex flex-row items-center px-3  "
            :class="{'bg-green-100' : ( cell[week] &&  cell[week].length > 0)}">
            <div class="flex flex-row justify-between w-full" v-if="formatCell?.template != 'template-100'">
                <div class="w-full flex flex-row items-center justify-start">
                    <div class="flex flex-col  text-gray-600">

                        <div class="">Ouvert le :</div>
                        <div class="">N° lot :</div>
                        <div class="">DDM/DLC :</div>
                    </div>
                    <div v-for="batch in limitTo2(cell[week],1) " :key="batch.id"
                        class="flex flex-col  px-2  text-gray-900">
                        <div class="">
                            {{formatDate(batch.opened_at)}}
                        </div>
                        <div class="">
                            {{batch.batch_number}}
                        </div>
                        <div class="">
                            {{formatDate(batch.best_before_date) }}

                        </div>

                    </div>
                </div>
                <div class="flex justify-center items-center right" v-if="cell[week].length > 1">

                    <div
                        class="bg-green-500 text-white rounded-full border border-gray-800 p-4 font-bold text-xl w-16 h-16">
                        +{{cell[week].length-1}}
                    </div>


                </div>
            </div>

            <div class="flex flex-col w-full justify-center text-center" v-if="formatCell?.template == 'template-100'">

                <div v-for="batch in limitTo2(cell[week],1)" :key="batch.number" class="flex flex-col  px-2  text-gray-900">

                    <div class="text-lg">
                        {{ batch.batch_number }}
                    </div>
                    <div class="text-gray-500" v-if="batch?.best_before_date != undefined">
                        DDM : {{ displayDate(batch.best_before_date) }}
                    </div>

                    <div class="text-gray-500" v-if="batch?.use_by_date != undefined"> 
                        DLC : {{ displayDate(batch.use_by_date) }}
                    </div>

                    <div class="text-gray-500" v-if="batch?.manufacturing_date != undefined"> 
                        Fab : {{ displayDate(batch.manufacturing_date) }}
                    </div>
                </div>

                <div class="flex flex-col  px-2  text-gray-900 font-semibold mt-1 italic w-full" v-if="cell[week].length > 1">

                    + {{cell[week].length - 1}} <span v-if="cell[week].length > 2">autres lots</span><span v-if="cell[week].length == 2">autre lot</span>
                </div>



            </div>

        </div>
        <div :id="`popover-hover-menu-${id}-${rank}-${index2}`" data-popover role="tooltip"
            class="absolute z-10 invisible inline-block w-96 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-300 rounded-lg shadow-sm opacity-0 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800">


            <FileColFullDisplayBatch v-if="formatCell?.template != 'template-100'" :items=" cell[week]" />
            <FileColDisplayBatch v-if="formatCell?.template == 'template-100'" :items=" cell[week]" />


            <div
                class="hidden py-1 bg-gray-50 border-t border-t-gray-300 text-xs justify-center font-normal h-full flex flex-row items-center px-3 ">
                <div class="cursor-pointer" @click="openDetails(cell)">
                    <span>Voir le détail <i class="fa-solid fa-up-right-from-square pl-2 w-6" /></span>
                </div>
            </div>
            <div data-popper-arrow />
        </div>
    </div>
</template>


<script>
    import { Popover } from 'flowbite';
    import FileColFullDisplayBatch from '@/components/five-col-cells/FileColFullDisplayBatch.vue'
    import FileColDisplayBatch from '@/components/five-col-cells/FileColDisplayBatch.vue'


    export default {
        name: 'CellDateBatchDDM',

        components: {
            FileColDisplayBatch, FileColFullDisplayBatch
        },
        props: {
            cell: Object,
            week: String,
            index2: Number,
            id: String,
            rank: Number,
            formatCell: Object
        },

        data() {

            return {
                popupHoverSelection: null,
                comment: "",


            }

        },
        methods: {
            displayDate(a) {
                if (a == "") return "-"

                let date = new Date(a)


                // Options pour formater la date
                const options = {

                    day: 'numeric',  // Jour du mois (21)
                    month: 'numeric',   // Nom complet du mois (Novembre)
                    year: 'numeric'  // Année sur 2 chiffres (24)
                };

                // Formater la date en français
                const formattedDate = new Intl.DateTimeFormat('fr-FR', options).format(date);

                return formattedDate;
            },
            limitTo2(a, max) {
                if (a == undefined) return []
                if (!Array.isArray(a)) return []

                if (a.length < max) return a

                return a.slice(0, max)

            },
            formatDate(d) {
                if (d == undefined) return ""
                if (d == "") return ""
                let s = d.split("T")
                return s[0]
            },
            onHoverPopup(rank, index, items) {
                //    console.log("onHoverPopup " + rank + "-" + index)

                this.comment = "id =" + index
                if (items == undefined) return
                if (items.length == 0) return

                if (this.popupHoverSelection == undefined) {

                    console.log("Create Hover ", rank, "-", index)

                    // set the popover content elementpopover-default-pv2-28
                    const $targetEl = document.getElementById('popover-hover-menu-' + this.id + '-' + rank + '-' + index);
                    if ($targetEl == undefined) {
                        console.log("Eroor targetEl ", rank, "-", index)
                        console.log('popover-hover-menu-' + this.id + '-' + rank + '-' + index)
                        alert('popover-hover-menu-' + this.id + '-' + rank + '-' + index)
                        return;
                    }
                    // set the element that trigger the popover using hover or click
                    const $triggerEl = document.getElementById('popover-hover-button-' + this.id + '-' + rank + '-' + index);
                    if ($triggerEl == undefined) {
                        console.log("Eroor triggerEl ", rank, "-", index)
                        console.log('popover-hover-button-' + this.id + '-' + rank + '-' + index)
                        alert('popover-hover-button-' + this.id + '-' + rank + '-' + index)
                        return;
                    }
                    // options with default values
                    const options = {
                        placement: 'bottom',
                        triggerType: 'hover',
                        offset: 10,
                        onHide: () => {
                            console.log('popover is shown');
                        },
                        onShow: () => {
                            console.log('popover is hidden');
                            console.log(this)
                        },
                        onToggle: () => {
                            console.log('popover is toggled');
                        },
                    };

                    // instance options object
                    const instanceOptions = {
                        id: 'popover-hover-menu-' + this.id + rank + '-' + "-" + index, //'popover-hover',
                        override: true
                    };

                    this.popupHoverSelection = new Popover($targetEl, $triggerEl, options, instanceOptions);


                }
                else {
                    console.log("Try to Open")
                    if (this.popupHoverSelection != undefined) this.popupHoverSelection.show()
                }



            },

        }

    }



</script>