<template>
  <!-- <TopNav class="hidden"/>-->


  <div class="relative h-screen grid  sm:ml-12"
    :class="{'grid grid-cols-12   ' : (chatOpened && isChatVisible), 'w-full':(!chatOpened&&isChatVisible)}">

    <SideNav />


    <div class="h-screen flex flex-col  "
      :class="{'  col-span-9' : (chatOpened &&isChatVisible), 'col-span-12': (!chatOpened && isChatVisible)}">

      <div class="flex-none  ">

        <div class=" w-full  bg-gray-800 flex-none" v-if="isMultiSite">
          <div class="inline-flex rounded-md shadow-sm" role="group">
            <button type="button" class="px-4 pb-1 pt-2 text-xs font-medium     cursor-pointer  text-white border-b-4 "
              :class="{'   font-bold  border-green-300': (selected==0), 'border-gray-800    hover:border-orange-500 ' : (selected!=0)}"
              @click="selectSite(0,'all')">
              Tous les sites
            </button>
            <button v-for="(entity, index) in user?.tenant?.entities" :key="entity.id" type="button"
              class="px-0 pb-1 pt-2  text-xs font-medium  text-white  border-b-4 cursor-pointer px-2"
              :class="{'   font-bold  border-green-300': (selected== (index+1)), ' border-gray-800  hover:border-orange-500 ' : (selected!= (index+1))}"
              @click="selectSite(index + 1, entity._id)">
              {{ entity.name }}
            </button>
          </div>
        </div>

      </div>
      <div class="flex-1 overflow-auto">
        <router-view class="w-full" :refresh="timestamp" />
      </div>


    </div>



    <div class="  h-screen col-span-3 max-w-xl " v-if="isChatVisible == true">
      <ChatComponent @closeChat="closeChat" />
    </div>

    <div class="absolute top-10 right-12" :class="{'hidden': (chatOpened || !isChatVisible)}" @click="onOpenChat">
      <button type="button"
        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
        <span class="li8jS" data-animate="false">
          <svg width="14" height="15" viewBox="0 0 14 15" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
            class="_1gQDs _3FkP7">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M7 0.714966C6.57172 0.714966 6.19841 1.00644 6.09453 1.42193C5.64458 3.22175 5.11525 4.31311 4.3567 5.07167C3.59815 5.83022 2.50678 6.35955 0.706966 6.8095C0.291477 6.91337 -3.32794e-07 7.28669 -3.32794e-07 7.71497C-3.32794e-07 8.14324 0.291477 8.51656 0.706966 8.62043C2.50678 9.07039 3.59815 9.59971 4.3567 10.3583C5.11525 11.1168 5.64458 12.2082 6.09453 14.008C6.19841 14.4235 6.57172 14.715 7 14.715C7.42828 14.715 7.80159 14.4235 7.90547 14.008C8.35542 12.2082 8.88475 11.1168 9.6433 10.3583C10.4019 9.59971 11.4932 9.07039 13.293 8.62043C13.7085 8.51656 14 8.14324 14 7.71497C14 7.28669 13.7085 6.91337 13.293 6.8095C11.4932 6.35955 10.4019 5.83022 9.6433 5.07167C8.88475 4.31311 8.35542 3.22175 7.90547 1.42193C7.80159 1.00644 7.42828 0.714966 7 0.714966Z">
            </path>
          </svg>
          <svg width="14" height="15" viewBox="0 0 14 15" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
            class="_1gQDs _1hXL0">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M7 0.714966C6.57172 0.714966 6.19841 1.00644 6.09453 1.42193C5.64458 3.22175 5.11525 4.31311 4.3567 5.07167C3.59815 5.83022 2.50678 6.35955 0.706966 6.8095C0.291477 6.91337 -3.32794e-07 7.28669 -3.32794e-07 7.71497C-3.32794e-07 8.14324 0.291477 8.51656 0.706966 8.62043C2.50678 9.07039 3.59815 9.59971 4.3567 10.3583C5.11525 11.1168 5.64458 12.2082 6.09453 14.008C6.19841 14.4235 6.57172 14.715 7 14.715C7.42828 14.715 7.80159 14.4235 7.90547 14.008C8.35542 12.2082 8.88475 11.1168 9.6433 10.3583C10.4019 9.59971 11.4932 9.07039 13.293 8.62043C13.7085 8.51656 14 8.14324 14 7.71497C14 7.28669 13.7085 6.91337 13.293 6.8095C11.4932 6.35955 10.4019 5.83022 9.6433 5.07167C8.88475 4.31311 8.35542 3.22175 7.90547 1.42193C7.80159 1.00644 7.42828 0.714966 7 0.714966Z">
            </path>
          </svg>
          <span class="_4pE1e"></span>
        </span> <span class="sr-only">Icon description</span>
      </button>





    </div>
  </div>

  <!-- drawer init and toggle -->
</template>
<style>
  ._1gQDs {
    display: block;
    will-change: transform;
  }

  .Wmjjd svg {
    display: block;
    width: 18px;
    height: 18px;
    position: relative;
    top: 1px;
  }

  span.li8jS ._3FkP7 {
    position: absolute;
    top: 2px;
    left: 3px;
    width: 14px;
    height: 15px;
    transform-style: preserve-3d;
    backface-visibility: visible;
    transform: rotateY(0);
  }

  .li8jS {
    position: relative;
    display: block;
    width: 18px;
    height: 18px;
    perspective: 1000px;
    transition: transform .3s var(--cubic-move);
  }

  [data-icon-only=false] .hfCtL {
    opacity: 1;
    width: var(--text-width);
    transition: width .35s var(--cubic-move), opacity .4s ease .25s;
  }

  ._1gQDs {
    display: block;
    will-change: transform;
  }

  .Wmjjd svg {
    display: block;
    width: 18px;
    height: 18px;
    position: relative;
    top: 1px;
  }

  span.li8jS ._1hXL0 {
    position: absolute;
    top: 1px;
    left: 0;
    width: 6px;
    height: 6px;
    transition: opacity .25s ease;
  }

  .hfCtL {
    --text-width: 64px;
    display: inline-block;
    position: relative;
    left: 4px;
    width: 0;
    opacity: 0;
    overflow: hidden;
    text-align: left;
    transition: width .35s var(--cubic-move), opacity .15s ease;
  }

  ._6jr41 {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    width: 100%;
    height: 100%;
    padding-bottom: 1.5px;
    transition: opacity .15s ease .1s;
  }


  span.li8jS {
    --particle-opacity: .7;
  }

  * {
    scrollbar-width: thin;
  }
</style>

<script>
  // import TopNav from '@/components/TopNav.vue'
  import SideNav from '@/components/SideNav.vue'
  import ChatComponent from '@/components/ChatComponent.vue'
  import { mapActions, mapGetters } from "vuex";
  export default {
    name: 'AdminLayout',
    data() {
      return {
        selected: 0,
        timestamp: null,
        chatOpened: false
      }
    },
    computed: {
      isMultiSite: function () {
        return (this.user?.tenant?.entities?.length > 0)
      },
      ...mapGetters({ user: "StateUser" }),
      isLoggedIn: function () {
        return this.$store.getters.isAuthenticated;
      },
      'isChatVisible': function () {
        if (this.user?.isChatActivated == true) return true;
        return false
      },
    },
    watch: {
      'user.entity_id': function () {
        this.timestamp = (new Date()).toISOString()
      }
    },
    mounted() {
      if (this.user?.entity_id == "66a4a19c8d43c894c147758a") this.selected = 1;
      if (this.user?.entity_id == "66a4a1cb8d43c894c147758b") this.selected = 2;

      this.timestamp = (new Date()).toISOString()
    },
    methods:
    {
      ...mapActions(["ChangeEntity"]),

      onOpenChat() {
        if (this.user?.isChatActivated != true) return;
        this.chatOpened = true
      },
      closeChat() {

        this.chatOpened = false
      }, openChat() {

        this.chatOpened = true
      },
      selectSite(n, id) {
        this.selected = n
        this.ChangeEntity(id)
      }
    },
    components: {
      SideNav, ChatComponent/*, TopNav*/
    }
  }
</script>